import {PLAYLIST_TYPES, playlistConstants} from "../../constants";
import {useEffect} from "react";
import {isEmpty, isNil} from "lodash";
import flatten from "lodash/flatten";

export {
    convertAddedContent,
    changeSecondToHMSTime,
    convertTagContent,
    convertedPlaylistItemInfo,
    getContentsTypeByDeviceType,
    useSideClick,
    addCheckStatusUpdate,
    moveCheckStatusUpdate,
    deleteCheckStatusUpdate,
    offArrowClick,
    convertSyncItemToPlaylistItem,
    makeContentsFormatGeneralPlaylist,
    makeTagsFormatTagPlaylist,
    isPlayOnceSupported
}
const effectItemObj = (playlistType) => {
    const effectItem = {
        inName: "",
        inDuration: 5,
        inDirection: "-1",
        outName: "",
        outDuration: 5,
        outDirection: "-1",
        startDate: "",
        expiredDate: "",
        inDelayDuration: 0,
        inDelayDirection: "1",
        outDelayDuration: 0,
        outDelayDirection: "1",
        inDelayDiv: 1,
        outDelayDiv: 1,
        repeatType: "",
        isIndependentPlay: "N",
        contiguous: false,
    };
    if (playlistType === '2') {
        effectItem.inDuration = 1;
        effectItem.outDuration = 1;
    }
    return effectItem;
};

const convertAddedContentObject = (item, playlistType) => {
    const contentsItem = {
        contentId: item.contentId,
        contentName: item.contentName,
        thumbFileId: item.thumbFileId,
        thumbFileName: item.thumbFileName,
        mediaType: item.mediaType,
        lastModifiedDate: item.lastModifiedDate,
        playTime: item.playTimeInString,
        totalSize: item.totalSize,
        deviceType: item.deviceType,
        deviceTypeVersion: item.deviceTypeVersion,
        expirationDate: item.expirationDate,
        resolution: item.resolution,
        mainFileExtension: item.mainFileExtension

    };
    if (item.playTimeInSeconds) {
        contentsItem.contentDuration = item.playTimeInSeconds;
    }
    return contentsItem;
}

const convertAdvertisementAddedContent = (item) => {
    return {
        contentId: item.contentId,
        contentName: item.contentName,
        thumbFileId: item.thumbFileId,
        thumbFileName: item.thumbFileName,
        mediaType: item.mediaType,
        lastModifiedDate: item.lastModifiedDate,
        playTime: item.playTimeInString,
        totalSize: item.totalSize,
        deviceType: item.deviceType,
        deviceTypeVersion: item.deviceTypeVersion,
        expirationDate: item.expirationDate,
        resolution: item.resolution,
        mainFileExtension: item.mainFileExtension,
        contentDuration: -1
    }
}

const convertAddedContent = (item, playlistType) => {
    let convertedItem;

    if (playlistType === playlistConstants.PLAYLISTTYPES[3].id) {
        convertedItem = convertAdvertisementAddedContent(item)
    } else if (!item.contentId) {
        convertedItem = {
            ...item,
            isSubPlaylist: true
        };
    } else {
        convertedItem = convertAddedContentObject(item, playlistType)
    }
    return {
        ...convertedItem,
        randomCount: 0,
        effects: effectItemObj(playlistType)
    }
};

const convertSnakeToCamel = (content) => {
    return {
        contentId: content.content_id,
        contentName: content.content_name,
        thumbFileId: content.thumb_file_id,
        thumbFileName: content.content_name,
        mediaType: content.media_type,
        expirationDate: content.expiration_date,
    }
}

const convertTagContent = (tagItem) => {
    return {
        contents: tagItem.contents ? tagItem.contents.map((content)=> {return convertSnakeToCamel(content)}) : [],
        tagConditionTitle: tagItem.tagConditionTitle ? tagItem.tagConditionTitle : '',
        tagConditions: tagItem.tagConditions ? tagItem.tagConditions: [],
        tagId: tagItem.tagId,
        name: tagItem.tagName,
    }
}

const changeSecondToHMSTime = (sec) =>{
    let result = "";
    sec = sec * 1;

    let oneMinute = 60;
    let oneHour = 3600;

    let hours = sec / oneHour;

    if (hours >= 1) {
        sec = sec % oneHour;
    }
    let minutes = sec / oneMinute;
    if (minutes >= 1) {
        sec = sec % oneMinute;
    }
    hours = Math.floor(hours);
    minutes = Math.floor(minutes);

    if (hours <10) {
        result += "0" + hours + ":";
    } else {
        result += hours + ":";
    }
    if (minutes <10){
        result += "0" + minutes + ":";
    }else{
        result += minutes + ":";
    }
    if(sec<10){
        result += "0" + sec;
    }else{
        result += sec;
    }

    return result;
}

const convertedPlaylistItemInfo = (playlistItem) => {
    const {playlistName, shareFlag, shuffleFlag, metaData,
        playlistType, deviceType, deviceTypeVersion, groupId, evennessPlayback, ignoreTag, defaultContentDuration} = playlistItem;
    return {
        creatorId: JSON.parse(localStorage.getItem('user')).id,
        defaultContentDuration, // tag Playlist
        evennessPlayback, // tag Playlist
        ignoreTag,
        groupId,
        playlistName,
        shareFlag,
        metaData,
        shuffleFlag: shuffleFlag =='N' ? false : true,
        deviceTypeVersion,
        deviceType : deviceType === 'IPLAYER' ? 'iPLAYER': deviceType,
        playlistType,
    }
}

const convertSyncItemToPlaylistItem = (sync) => {
    const syncItems = flatten(sync.filter((syncItem) => !syncItem.isDeleteCheck).map((syncInfo, index) => {
        syncInfo.items.map((item) => {
            item.syncOrder = item.itemIndex +1;
            item.syncPlayId = index;
            item.syncDuration = parseInt(item.time.hour) * 3600 + parseInt(item.time.min) * 60 + parseInt(item.time.sec);
            item.syncStatus = syncInfo.isSyncCheck ? 'Y' : 'N';
        });
        return syncInfo.items;
    }));

    return syncItems.map((content, index) => {
        return {...content, contentOrder: index +1, contentDuration: content.syncDuration}
    });
};

const makeContentsFormatGeneralPlaylist = (playlistInfo) => {
    return playlistInfo.contents.map((content, index) => {
        content.contentOrder = index + 1;
        content.contentId = content.contentId || content.playlistId;
        content.effects = convertEffectForContentItem(content.effects, content.contentId , content.contentDuration, index);

        if (isNil(content.contentDuration) && isEmpty(content.playTime) && (!isPlayOnceSupported(content.mediaType) && playlistInfo.playlistType !== '4'
            || isPlayOnceSupported(content.mediaType))) {
            if (playlistInfo.deviceType.toUpperCase() === 'IPLAYER') {
                content.contentDuration = 30;
                content.effects = convertEffectForContentItem(content.effects, content.contentId, 30, index);
            } else {
                content.contentDuration = 5;
                content.effects = convertEffectForContentItem(content.effects, content.contentId, 5, index);
            }
        }
        if (content.contentTag) {
            content.contentTag.contentId = content.contentId;
            content.contentTag.contentOrder = index + 1;
        }
        if (playlistInfo.playlistType) {
            content.contentDuration = content.contentDuration > 4 ? content.contentDuration : '';
        }
        return content;
    });
}

const convertEffectForContentItem = (effect, contentId, contentDuration, index) => {
    const convertedEffect = {...effect};
    convertedEffect.contentOrder = index + 1;
    convertedEffect.contentId = contentId;
    convertedEffect.contentDuration = contentDuration;
    return  convertedEffect;
}

const makeTagCondition = (tag) => {
    if (tag.tagType === 0) {
        return tag.contentTagConditions.map((condition)=> condition.checkCondition && condition.tagConditionId.toString()).filter((item)=> !isEmpty(item));
    } else if (tag.tagType === 1) {
        return tag.contentTagConditions.map((condition)=> condition.tagCondition);
    } else {
        return tag.contentTagConditions.map((condition)=> condition.tagConditionId);
    }
};

const makeTagsFormatTagPlaylist = (tags) => {
    return tags.map((tag, index)=> {
        if(!isEmpty(tag.contentTagConditions)) {
            tag.tagConditions = makeTagCondition(tag);
        } else if(!isEmpty(tag.tagConditions)) {
            tag.tagConditions = tag.tagConditions.map((condition)=> condition.tag_condition_id);
        }
        tag.expireDate = tag.expiredDate;
        tag.order = index +1;
        return tag;
    })
}

export const deviceMappingContents = [
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 1,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','FTP','CIFS'],
    },
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 2,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS'],
    },
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 3,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM'],
    },
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 4,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM','HTML', 'URL','SAPP'],
    },
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 5,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM','HTML', 'URL','SAPP'],
    },{
        deviceType: 'SPLAYER',
        deviceTypeVersion: 6,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM','HTML', 'URL','SAPP'],
    },
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 7,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM','HTML', 'URL','SAPP'],
    },
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 8,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM','HTML', 'URL','SAPP'],
    },
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 9,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM','HTML', 'URL','SAPP'],
    },
    {
        deviceType: 'SPLAYER',
        deviceTypeVersion: 10,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM','HTML', 'URL','SAPP'],
    },
    {
        deviceType: 'LPLAYER',
        deviceTypeVersion: 1,
        contentsType: ['IMAGE','MOVIE','OFFICE','PDF','FLASH'],
    },
    {
        deviceType: 'WPLAYER',
        deviceTypeVersion: 1,
        contentsType: ['LFD','IMAGE','MOVIE','SOUND','DLK','ADS','FTP','CIFS','HTML','URL'],
    },
    {
        deviceType: 'IPLAYER',
        deviceTypeVersion: 2,
        contentsType: ['LFD','IMAGE','MOVIE','OFFICE','PDF','FLASH', 'SOUND','DLK','FTP','CIFS','STRM','HTML', 'URL'],
    },
    {
        deviceType: 'APLAYER',
        deviceTypeVersion: 1,
        contentsType: ['IMAGE','MOVIE','SOUND']
    }
]

const getContentsTypeByDeviceType = (deviceType, deviceTypeVersion) => {
    const filteredMap = deviceMappingContents.filter((mappingDeviceType) => {
        if (deviceType === 'SPLAYER' && mappingDeviceType.deviceType === deviceType) {
            if (mappingDeviceType.deviceTypeVersion === parseInt(deviceTypeVersion)) {
                return mappingDeviceType;
            }
        } else {
            if (mappingDeviceType.deviceType === deviceType.toUpperCase()) {
                return mappingDeviceType;
            }
        }
    })[0];

    if (filteredMap) {
        return filteredMap.contentsType;
    } else {
        return ['IMAGE','MOVIE','SOUND'];
    }
}


const useSideClick = (ref, callback) => {
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback(e);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

const addCheckStatusUpdate = (items, itemIndex) => {
    return items.map(({item, index}) => {
        if (index > itemIndex) {
            return {item, index: index + 1}
        } else if (index <= itemIndex) {
            return {item, index}
        }
    })
}
const moveCheckStatusUpdate = (items, itemIndex) => {
    const {hoverIndex, dragIndex} = itemIndex;
    return items.map(({item, index}) => {
        if (hoverIndex < dragIndex) {
            if (index >= hoverIndex && index <dragIndex) {
                return {item, index: index +1}
            } else if (index === dragIndex) {
                return {item, index: hoverIndex}
            } else {
                return {item, index}
            }
        } else {
            if (index < hoverIndex && index > dragIndex) {
                return {item, index: index - 1}
            } else if (index === dragIndex) {
                return {item, index: hoverIndex}
            } else {
                return {item, index}
            }
        }
    })
}

const deleteCheckStatusUpdate = (items, itemIndex) => {
    return items.map(({item, index}) => {
        if (index > itemIndex) {
            return {item, index: index -1}
        } else if (index < itemIndex) {
            return {item, index}
        }
    }).filter((item) => !isEmpty(item)).sort();
}

const offArrowClick = () => {
    const arrows = [...document.getElementsByClassName("playlistImage_effect_button")];
    arrows.forEach(arrow => {
        arrow.classList.remove("clicked");
    })
}

const isPlayOnceSupported = (mediaType) => {
    if(isNil(mediaType))
        return false;
    if(mediaType === 'FTP' || mediaType === 'CIFS' || mediaType === 'ADS')
        return true;
    return false;
}
