import TextInput from "../../components/input/TextInput";
import React, {useState, useRef, useEffect} from "react";
import {useTranslation} from "react-i18next";
import isEmpty from 'lodash/isEmpty';
import {changeSecondToHMSTime, isPlayOnceSupported} from './playlistUtil';
import {checkNumberValidation} from "../../helper";
import {PLAYLIST_TYPES} from "../../constants";
import {playlist} from "../../reducers/playlist.reducer";
const IPLAYER = 'IPLAYER';

const TimeSettingWrap = ({content, playlistType, deviceType,  index, updateItem, type}) => {
    const {t} = useTranslation();
    const {isSubPlaylist, playTime, contentDuration, mediaType, duration} = content;
    const [defaultPlaySec, setDefaultPlaySec] = useState("5");
    const [defaultPlayMin, setDefaultPlayMin] = useState("0");
    const [defaultPlayHour, setDefaultPlayHour] = useState("0");
    const [disabled, setDisabled] = useState("");
    const [durationSec, setDurationSec] = useState("");
    const DLK_MEDIA_TYPE = "DLK";

    useEffect(() => {

        if (playTime != null && playTime != "" && playlistType !== PLAYLIST_TYPES.ADVERTISEMENT) {
            if(mediaType === DLK_MEDIA_TYPE){
                if(contentDuration > 0 && contentDuration !== ''){
                    let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                    setDefaultPlaySec(hmsTimeArr[2]);
                    setDefaultPlayMin(hmsTimeArr[1]);
                    setDefaultPlayHour(hmsTimeArr[0]);
                }
                else{
                    setDefaultPlayHour("00");
                    setDefaultPlayMin("00");
                    setDefaultPlaySec("05");                    
                }
            }
            else{
                let arr = playTime.split(":");
                setDefaultPlaySec(arr[2]);
                setDefaultPlayMin(arr[1]);
                setDefaultPlayHour(arr[0]);
                setDisabled("disabled")
                setDurationSec(changeSecondToHMSTime(playTime));
            }
        } else if (playlistType === PLAYLIST_TYPES.ADVERTISEMENT) {
            if (contentDuration > 0 && contentDuration !== '') {
                let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                setDefaultPlayHour(parseInt(hmsTimeArr[0]))
                setDefaultPlayMin(parseInt(hmsTimeArr[1]));
                setDefaultPlaySec(parseInt(hmsTimeArr[2]));
            } else {
                setDefaultPlayMin("-");
                setDefaultPlaySec("-");
                setDefaultPlayHour("-")
            }

        } else {
            /*if (deviceType && deviceType.toUpperCase() == IPLAYER) {
                setDurationSec("30");
            } else {
                setDurationSec("5");
            }*/
            if (contentDuration && !isSubPlaylist) {

                if (contentDuration === 0 && isPlayOnceSupported(mediaType)) {
                    setDefaultPlayMin("0");
                    setDefaultPlayHour("0");
                    setDefaultPlaySec("0");
                } else {
                    let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                    setDefaultPlayHour(parseInt(hmsTimeArr[0]))
                    setDefaultPlayMin(parseInt(hmsTimeArr[1]));
                    setDefaultPlaySec(parseInt(hmsTimeArr[2]));
                }
            } else {
                if (playlistType === PLAYLIST_TYPES.TAG) {
                    let hmsTimeArr = changeSecondToHMSTime(Number(duration)).split(":");
                    setDefaultPlayHour(parseInt(hmsTimeArr[0]))
                    setDefaultPlayMin(parseInt(hmsTimeArr[1]));
                    setDefaultPlaySec(parseInt(hmsTimeArr[2]));
                } else {
                    if (contentDuration === 0 && isPlayOnceSupported(mediaType)) {
                        setDefaultPlayMin("0");
                        setDefaultPlayHour("0");
                        setDefaultPlaySec("0");
                    } else if (deviceType && deviceType.toUpperCase() === IPLAYER) {
                        setDefaultPlayMin("0");
                        setDefaultPlayHour("0");
                        setDefaultPlaySec("30");
                    }  else {
                        setDefaultPlayMin("0");
                        setDefaultPlayHour("0");
                        setDefaultPlaySec("5");
                    }
                }
            }
        }
    }, [contentDuration, duration]);

    useEffect(() => {
        let contentPlayingTime;
        if (playlistType === PLAYLIST_TYPES.TAG) {
            let hmsTimeArr = changeSecondToHMSTime(Number(duration)).split(":");
            contentPlayingTime = parseInt(hmsTimeArr[0]) * 3600 + parseInt(hmsTimeArr[1]) * 60 + parseInt(hmsTimeArr[2]);
        } else if (playlistType === PLAYLIST_TYPES.ADVERTISEMENT){
            if (contentDuration > 0 && contentDuration !== '') {
                let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                contentPlayingTime = parseInt(hmsTimeArr[0]) * 3600 + parseInt(hmsTimeArr[1]) * 60 + parseInt(hmsTimeArr[2]);
            } else {
                contentPlayingTime = -1;
            }
        } else {

            if (contentDuration > 0 && contentDuration !== '') {
                let hmsTimeArr = changeSecondToHMSTime(Number(contentDuration)).split(":");
                contentPlayingTime = parseInt(hmsTimeArr[0]) * 3600 + parseInt(hmsTimeArr[1]) * 60 + parseInt(hmsTimeArr[2]);
            } else if (contentDuration === 0 && isPlayOnceSupported(content.mediaType)) {
                contentPlayingTime= 0;
            } else{
                contentPlayingTime = parseInt(defaultPlayHour) * 3600 + parseInt(defaultPlayMin) * 60 + parseInt(defaultPlaySec);
            }
        }
        if (playlistType !== PLAYLIST_TYPES.ADVERTISEMENT && (contentPlayingTime < 5 && !isPlayOnceSupported(content.mediaType))) {
            updateItem({...content, ...wrappingDuration(5)}, index)
        } else {
            updateItem({...content, ...wrappingDuration(contentPlayingTime)}, index)
        }


    }, []);

    const timeSettingDisabled = (mediaType) => {
        if(mediaType === DLK_MEDIA_TYPE) return false;
        return (!isEmpty(playTime)|| (contentDuration === 0 && isPlayOnceSupported(mediaType)) || playlistType === '4') ? true : false
    };

    const className = type === 'tag' ? 'time_setting_wrap tagplaylist' : 'time_setting_wrap';

    const wrappingDuration = (contentPlayingTime) => {

        return playlistType === '5' ? {
            duration: contentPlayingTime
        } : {
            contentDuration: contentPlayingTime
        }
    }

    const getOriginalPlayTime = () =>{
        let arr = playTime.split(":");
        return  parseInt(arr[0]) * 3600 + parseInt(arr[1]) * 60 + parseInt(arr[2]);
    }
    const resetOriginalDLKTime = (originalDLKPlayTime) =>{
        let arr = playTime.split(":");
        setDefaultPlaySec(arr[2]);
        setDefaultPlayMin(arr[1]);
        setDefaultPlayHour(arr[0]);
        updateItem({...content, ...wrappingDuration(originalDLKPlayTime)}, index)
    }

    const setMinDLKTime = () =>{
        setDefaultPlayHour("00");
        setDefaultPlayMin("00");
        setDefaultPlaySec("05");
        updateItem({...content, ...wrappingDuration(5)}, index)
    }

    const updateDLKPlayingTime = (contentPlayingTime, timeValue, timeUnit) =>{
        if(contentPlayingTime < 5) {
            //DLK time cannot be less than 5 seconds.
            setMinDLKTime();                                            
            return;
        }
        let originalDLKPlayTime = getOriginalPlayTime();
        if(originalDLKPlayTime < contentPlayingTime) {
            //DLK new play time can't be more than original play time. Overwrite original value.
            resetOriginalDLKTime(originalDLKPlayTime)                                           
            return;
        }
        //New time is within acccepted limits, so we can safely update the DLK play duration now:-  
        switch(timeUnit){
            case "hour": setDefaultPlayHour(timeValue);
                            break;
            case "min": setDefaultPlayMin (timeValue);
                            break;
            case "sec": setDefaultPlaySec (timeValue);
                            break;
            default: setDefaultPlaySec (timeValue);
        }
        updateItem({...content, ...wrappingDuration(contentPlayingTime)}, index)
    }

    return (
        <div className={className} style={{left: (content.isSubPlaylist|| playlistType === '5') ? -96 : -140}}>
            {
                isSubPlaylist ? <TextInput type={"text"} className="light_gray ta_c" width={100} textAlign={'center'}
                                           value={content.playTime} disabled={disabled}/>
                    :
                    <div style={{width:'inherit'}}>
                        <TextInput type={"text"} className="light_gray ta_c" width={35} textAlign={'center'} value={defaultPlayHour}
                            onChange={(e) => {
                                if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                setDefaultPlayHour(e.target.value);
                            }}
                            onBlur={(e) => {
                                if (e.target.value !== '' || mediaType == DLK_MEDIA_TYPE) {
                                    const hourValue = checkNumberValidation(defaultPlayHour, 0, 23);
                                    const contentPlayingTime = parseInt(hourValue * 3600) + parseInt(defaultPlayMin) * 60 + parseInt(defaultPlaySec);
                                    if(mediaType == DLK_MEDIA_TYPE){
                                        updateDLKPlayingTime(contentPlayingTime, hourValue, "hour")
                                        return;                                        
                                    }                                    
                                    setDefaultPlayHour(hourValue);
                                    updateItem({...content, ...wrappingDuration(contentPlayingTime)}, index)
                                }
                            }}
                            disabled={timeSettingDisabled(content.mediaType)}
                        />
                        <span className="space">:</span>
                        <TextInput type={"text"} className="light_gray ta_c" width={35} textAlign={'center'} min={0} max={59} value={defaultPlayMin}
                           onChange={(e) => {
                               if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                setDefaultPlayMin(e.target.value);
                            }}
                            onBlur={(e) =>{
                                if (e.target.value !== '' || mediaType == DLK_MEDIA_TYPE) {
                                    const minuteValue = checkNumberValidation(e.target.value, 0, 59);
                                    const contentPlayingTime = parseInt(defaultPlayHour) * 3600 + parseInt(minuteValue) * 60 + parseInt(defaultPlaySec);
                                    if(mediaType == DLK_MEDIA_TYPE){
                                        updateDLKPlayingTime(contentPlayingTime, minuteValue, "min")
                                        return;
                                    }   
                                    setDefaultPlayMin(minuteValue);
                                    updateItem({...content, ...wrappingDuration(contentPlayingTime)}, index)
                                }
                            }}
                            disabled={timeSettingDisabled(content.mediaType)}
                        />
                        <span className="space">:</span>
                        <TextInput type={"text"} className="light_gray ta_c" width={37} textAlign={'center'} min={0} max={59} value={defaultPlaySec}
                           onChange={(e) => {
                                if (Number.isNaN(parseInt(e.target.value)) && e.nativeEvent.data !== null) return;
                                setDefaultPlaySec(e.target.value);
                            }}
                            onBlur={(e) => {
                                if (e.target.value !== '' || mediaType == DLK_MEDIA_TYPE) {
                                    const secondValue = checkNumberValidation(e.target.value, 0, 59);
                                    const contentPlayingTime = parseInt(defaultPlayHour) * 3600 + parseInt(defaultPlayMin) * 60 + parseInt(secondValue);
                                    if(mediaType == DLK_MEDIA_TYPE){
                                        updateDLKPlayingTime(contentPlayingTime, secondValue, "sec")
                                        return;
                                    }
                                    if (contentPlayingTime < 5) {
                                        setDefaultPlaySec(5);
                                        updateItem({...content, ...wrappingDuration(5)}, index)
                                    } else {
                                        setDefaultPlaySec(secondValue);
                                        updateItem({...content, ...wrappingDuration(contentPlayingTime)}, index)
                                    }
                                }
                            }}
                            disabled={timeSettingDisabled(content.mediaType)}
                        />
                    </div>
            }
        </div>

    )
}

export default TimeSettingWrap;
