import React from 'react';
import {contentService, playlistService} from "../../../services";
import {connect} from "react-redux";
import {contentAction, menuAction, playlistAction, popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import SubTab from "../../tab/SubTab";
import './PlaylistDetailView.css';
import PlaylistDetailTab from "./PlaylistDetailTab";
import {withTranslation} from "react-i18next";
import VersionTable from "./VersionTable";
import PlayListTagHistory from "./PlayListTagHistory";
import Category from "../../category/Category";
import {toastr} from 'helper/toastrIntercept';
import ContentThumbnail from "../../image/ContentThumbnail";
import {MISOptContext} from "../../misopt";
import {commonConstants, PLAYLIST_TYPES} from "../../../constants";
import {initCacheTable} from "../../../helper/cache/tableCache";
import {getErrorMessage} from "../../../helper/responseHandler";                                                        //Defect[DF200928-00442]
import {getMainThumbnailContentDetails, allSubplaylist, getAllContentsIds, getTagsContentMap} from "../../../helper";
import Checkbox from "../../checkbox/Checkbox";
import WhiteButton from "../../button/WhiteButton";
import { isEmpty, isNil } from 'lodash';
import Moment from "react-moment";
import {userDateFormat} from '../../../helper/user/userDateFormat'

const subTabs = [
    'BUTTON_DETAIL_P',
    'COM_LIST_TITLE_VERSION',
    'DID_MAIN_CATEGORY',
];

const subTabs_Tag = [
    'BUTTON_DETAIL_P',
    'COM_VOICE_HISTORY_KR_HIST',
    'DID_MAIN_CATEGORY',
];


const categoryStyle = {
    width: '100%' ,
    position: 'relative',
    top: 10,
    height :400
};
const  innerStyle = {
    height :360
}

class PlaylistDetailView extends React.Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);
        this.state = {
            playlist : undefined,
            originPlaylist : undefined,
            mainThumbnail: undefined,
            thumbFileIds : undefined,
            isSubplaylists : undefined,
            subTab: subTabs[0],
            selectedVersionId: undefined,
            selectedCategories : [],
            tagHistory : [],
            tagHistoryLoading : false,
            isInformationClick: false,
            contentIndex: 1,
        };
    }

    componentDidMount() {
        const {id,t} = this.props;
        //const {mainThumbnail} = this.state;

        if ( typeof id === 'undefined' || id === '') {
            return;
        }

        playlistService.fetchPlaylistById(id).then(res=> {

            let selectedCategories = [];
            if (typeof res.items.categoryList !== 'undefined' && res.items.categoryList.length > 0) {
                selectedCategories = res.items.categoryList.map(el => String(el.groupId));
            }

            let mainThumbnail = "NOIMAGE_THUMBNAIL";
            let thumbFileIds = [];
            let isSubplaylists = [];

            if( res.items != null  && res.items.playlistType === '5'){
                if (typeof res.items.tags !== 'undefined' && res.items.tags.length > 0) {
                    res.items.tags.map(el => {
                        if( typeof  el.contents !== 'undefined' && el.contents.length > 0 ) {
                            el.contents.map(content => {
                                thumbFileIds.push(content.thumbFileId);

                            })
                        }
                    });
                }
            } else {

                if (typeof res.items.contents !== 'undefined' && res.items.contents.length > 0) {
                    res.items.contents.map(el => {
                        thumbFileIds.push(el.thumbFileId);
                        isSubplaylists.push(el.isSubplaylist);
                    });
                }
            }

            if(thumbFileIds.length > 0) {
                mainThumbnail = thumbFileIds[0];
            }

            this.setState({
                playlist: res.items,
                originPlaylist : res.items,
                playlistTitle: res.items.playlistName,
                mainThumbnail: mainThumbnail,
                playlistVersions: res.items.playlistVersions,
                selectedCategories: selectedCategories,
                selectedVersionId: res.items.versionId,
                thumbFileIds : thumbFileIds,
                isSubplaylists : isSubplaylists,
                creatorId: res.items.creatorId,
                organizationName: res.items.organizationName
            });

            if (typeof res.items !== 'undefined' && res.items.playlistType === '5') {
                this.setState({tagHistoryLoading : true});
                playlistService.fetchPlaylistTagHistory(id).then( res => {
                    this.setState({tagHistory : res.items});
                    this.setState({tagHistoryLoading : false});
                }).catch(() => {
                    this.setState({tagHistoryLoading : false});
                });
            }

        }).catch(error => {
            this.closePopup(id);
            console.log(error);
            toastr.error(getErrorMessage(error));                                                   //Defect[DF200928-00442]
        });
    }

    getAuthority(id) {
        const {authority}  = this.context;
        let auth = {};
        if (authority !== undefined && id !== undefined) {
            auth = authority[id];
        }
        return auth !== undefined ? auth : {};
    }

    isEditDisabled = (playlist) =>{
        const authority = this.getAuthority('CONTENT_PLAYLIST_RULESET');
        const userId = JSON.parse(localStorage.getItem('user')).id;
        if(playlist.shareFlag !== 1 && !authority.MANAGE && userId !== playlist.creatorId){
            return true;
        }
        else{
            return false;
        }
    }

    openContentDetail = (contentId) => {
        const { openDetailView } = this.props;
        openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: "CONTENT", id: contentId,});
    };

    openPlaylistDetail = (playlistId) => {
        const { openDetailView } = this.props;
        openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'PLAYLIST', id: playlistId});
    }

    closePopup = (playlistId) => {
        const {closePopup} = this.props;
        closePopup(playlistId);
    };

    clickSave = () => {
        const {playlist, selectedVersionId, selectedCategories, subTab, originPlaylist } = this.state;
        const {t} = this.props;

        //category
        if(subTab === subTabs[2]){

            const playlistIds = [];
            playlistIds.push(playlist.playlistId);
            const obj = {playlistIds : playlistIds, categoryIds : selectedCategories};

            playlistService.editCategory(obj).then(res => {
                console.log(res);
                toastr.success(t("COM_TEXT_SUCCESS_P"));
                this.props.onSave && this.props.onSave();
            }).catch(error => {
                console.log(error);
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
            }).finally(() => {
                this.closePopup(playlist.playlistId);
            });
        }
        else if(subTab === subTabs[1] || subTab === subTabs[0]) {

            let playlistForUpdate = {};

            if(subTab === subTabs[1]){ // change version
                playlistForUpdate = {
                    playlistId: originPlaylist.playlistId,
                    playlistName: originPlaylist.playlistName,
                    groupId: originPlaylist.groupId,
                    shareFlag: originPlaylist.shareFlag,
                    metaData: originPlaylist.metaData,
                    versionId: selectedVersionId || playlist.version,
                };
            } else {  // modify playlist Detail
                playlistForUpdate = {
                    playlistId: playlist.playlistId,
                    playlistName: (playlist.playlistName).trim()||' ',
                    groupId: playlist.groupId,
                    shareFlag: playlist.shareFlag,
                    metaData: playlist.metaData||'',
                    versionId : playlist.versionId,
                };
            }
            
            playlistService.updatePlaylist(playlistForUpdate).then(res => {
                toastr.success(t("COM_TEXT_SUCCESS_P"));
                
                if(this.props.onSave){
                    this.props.onSave();
                }
            }).catch(error => {
                console.log(error);
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
            }).finally(() => {
                this.closePopup(playlistForUpdate.playlistId);
            });

        }
        else if(subTab === subTabs_Tag[1]) {  // Tag
            this.closePopup(playlist.playlistId);
            this.props.onSave();
        }
    };

    onClickThumbnail = (thumbFileId, index) => {
        this.setState({mainThumbnail: thumbFileId});
        this.setState({contentIndex: (index + 1)});
    };

    onInformationClick = () => {
        const { isInformationClick } = this.state;
        this.setState({ isInformationClick: !isInformationClick});
    };

    downloadContent = (contents, isAllDownload) => {
        const {t, addPopup, closePopup, contentDownloading} = this.props;

        let contentIds;
        if(isAllDownload) {
            contentIds = getAllContentsIds(contents)
        } else {
            contentIds = contents.contentId;
        }
        const params = {
            contentIds: (isAllDownload) ? contentIds : [contentIds]
        };
        contentDownloading(true);
        addPopup({
            id: "CONTENT_DOWNLOADING",
            type: commonConstants.LOADING_POPUP,
            title: "Downloading",
            modal: false,
            isDraggable: true,
        });
        contentService.contentDownload(params).then((res) => {})
        .catch((error) => {
            toastr.error(t('COM_DOWNLOAD_FAILED'));
        })
        .finally(() => {
            closePopup("CONTENT_DOWNLOADING");
            contentDownloading(false);
        });
    };

    onClickSubTab = (id) => {
        this.setState({subTab: id});
    };

    onChangeDetail = (playlist) => {

        this.setState({
            playlist: playlist,
        });
    };

    onChangeVersionId = (versionId) => {
        this.setState({
            selectedVersionId: versionId,
            contentIndex:1
        }, this.handleVersionId)
    };
    
    handleVersionId =() =>{
        const {id,t} = this.props;

        playlistService.fetchPlaylistByVersionId(id, this.state.selectedVersionId).then(res => {

            let mainThumbnail = "NOIMAGE_THUMBNAIL";
            let thumbFileIds = [];
            let isSubplaylists = [];

            if( res.items != null  && res.items.playlistType === '5'){
                if (typeof res.items.tags !== 'undefined' && res.items.tags.length > 0) {
                    res.items.tags.map(el => {
                        if( typeof  el.contents !== 'undefined' && el.contents.length > 0 ) {
                            el.contents.map(content => {
                                thumbFileIds.push(content.thumbFileId);

                            })
                        }
                    });
                }
            } else {

                if (typeof res.items.contents !== 'undefined' && res.items.contents.length > 0) {
                    res.items.contents.map(el => {
                        thumbFileIds.push(el.thumbFileId);
                        isSubplaylists.push(el.isSubplaylist);
                    });
                }
            }

            if(thumbFileIds.length > 0) {
                mainThumbnail = thumbFileIds[0];
            }

            this.setState({
                mainThumbnail: mainThumbnail,
                thumbFileIds : thumbFileIds,
            });

        }).catch(error => {
            console.log(error);
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }).finally(() => {

        });
    }

    onEditPlaylist = (playlist) => {
        const {t, addTab, loadPlaylist, loadContent, initEditPlaylist, menu, addPopup, closePopup, activeTab} = this.props;

        if(menu.tabs.filter(tab => tab.id === "EDIT_PLAYLIST").length > 0) {
            activeTab("EDIT_PLAYLIST");
            addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                message: t("MESSAGE_UPLOADER_REALLY_CANCEL_P"),
                title: t("COM_BUTTON_CONFIRM"),
                onClickYes: ()=> {
                    new Promise((resolve) => {
                        closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                        initCacheTable("EDIT_PLAYLIST");
                        resolve();
                    }).then(
                        initEditPlaylist(playlist)
                    ).then(
                        loadPlaylist(playlist)
                    ).then(
                        loadContent('EDIT_PLAYLIST')
                    )
                },
                onClose: () => {
                    closePopup(commonConstants.COMMON_CONFIRM_POPUP)}
            });
        }
        else {
            const tab = {id: 'EDIT_PLAYLIST', title: t("TEXT_PLAYLIST_EDIT_P"), active: true, close: true};
            const add = () => addTab(tab);

            new Promise((resolve) => {
                add();
                resolve();
            }).then(
                initEditPlaylist(playlist)
            ).then(
                loadPlaylist(playlist)
            ).then(
                loadContent('EDIT_PLAYLIST')
            )
        }
    };

    onCategoryCheck = (categoryIds) => {
        this.setState({selectedCategories : categoryIds})
    }

    render() {
        const {t, zIndex,id} = this.props;
        const authority = this.getAuthority('CONTENT_PLAYLIST_RULESET');
        const {playlist, playlistTitle, mainThumbnail, thumbFileIds, isSubplaylists, subTab, playlistVersions, selectedVersionId, tagHistory, tagHistoryLoading} = this.state;
        let subTabArr;
        let tagsContentMap;
        let contentDetail;
        const { isInformationClick } = this.state;
        const { contentIndex } = this.state;
        const {getUserDateTimeFormat,getUserDateFormat} = userDateFormat();
        if( typeof  playlist === 'undefined'){
            return  "";
        }
        if(playlist.playlistType === PLAYLIST_TYPES.TAG) {
            tagsContentMap = getTagsContentMap(playlist);
            contentDetail = getMainThumbnailContentDetails(tagsContentMap, mainThumbnail, playlist.playlistType);
        } else {
            contentDetail = getMainThumbnailContentDetails(playlist.contents, mainThumbnail, playlist.playlistType);
        }

        if( playlist != null  && playlist.playlistType === '5'){
            subTabArr = subTabs_Tag;
        }
        else {
            subTabArr = subTabs;
        }

        return(
            <MISDialog
                dialog={{
                    title: playlistTitle,
                    isDraggable: true,
                    closeOnEscape: true,
                    allowMinimize: true,
                    width: 861,
                    height: 495,
                    position: {x: -200, y: -200},
                    zIndex: zIndex,
                    popupId : id,
                    onClose: () => this.closePopup(playlist.playlistId),
                }}
                buttons={{
                    rightButtons: [
                        {
                            id : 'PLAYLIST_DETAIL_EDIT',
                            title: t('COM_BUTTON_EDIT'),
                            disable: this.isEditDisabled(playlist),
                            onClick: () => {
                                this.onEditPlaylist(playlist);
                                this.closePopup(playlist.playlistId);
                            },
                            authority : authority.CREATE || authority.MANAGE
                        },
                        {
                            id : 'PLAYLIST_DETAIL_EDIT_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: () => {
                                playlist.playlistName.trim() !== "" && this.clickSave()
                            },
                            authority : authority.CREATE || authority.MANAGE,
                        },
                        {
                            id : 'PLAYLIST_DETAIL_EDIT_CLOSE',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: () => this.closePopup(playlist.playlistId)
                        }
                    ]
                }}>
                {
                    playlist !== undefined &&
                    <div className="popup_contents clearfix" style={{display:'flex'}}>
                        <div className="playlist_imgview mt25" style={{verticalAlign:'baseline', width:370}}>
                            <div className="preview_wrap clearfix" align="center" style={{width:370}}>
                                {(contentDetail && isInformationClick) &&
                                    <div className="content_info" style={{ height: 220, width: 370, position: "absolute", zIndex: 2}}>
                                        {!contentDetail.isSubPlaylist ? (
                                        <div className={"playlist_content_detail"} style={{ height: 180, width: 330, margin: 20, position: "absolute"}}>
                                            <table>
                                                <colgroup>
                                                <col width="110px" />
                                                <col width="220px" />
                                                </colgroup>
                                                <tbody>
                                                    <tr className="bullet_line">
                                                        <th>
                                                            {t("TEXT_CONTENT_NAME_P")}
                                                        </th>
                                                        <td>
                                                            {<a href="#" onClick={() => this.openContentDetail(contentDetail.contentId)}style={{whiteSpace:'normal', color: 'black'}}>{contentDetail.contentName}</a>}
                                                        </td>
                                                    </tr>
                                                    <tr className="bullet_line">
                                                        <th>
                                                            <span>{t("MIS_SID_CONTENT_TYPE")}</span>
                                                        </th>
                                                        <td>
                                                            {contentDetail.mediaType}
                                                        </td>
                                                    </tr>
                                                    <tr className="bullet_line">
                                                        <th>
                                                            <span>{t("MIS_TEXT_TAG_P")}</span>
                                                        </th>
                                                        <td>
                                                            {!(isNil(contentDetail.contentTagValue) || isEmpty(contentDetail.contentTagValue)) ? contentDetail.contentTagValue : "-"}
                                                        </td>
                                                    </tr>
                                                    <tr className="bullet_line">
                                                        <th>
                                                        <span>{t('COM_TEXT_MODIFY_DATE_P')}</span>
                                                        </th>
                                                        <td>
                                                            <Moment format={getUserDateTimeFormat()}>{contentDetail.lastModifiedDate}</Moment>
                                                        </td>
                                                    </tr>
                                                    <tr className="bullet_line">
                                                        <th>
                                                            <span>{t("TEXT_CREATOR_P")}</span>
                                                        </th>
                                                        <td>
                                                            {(playlist.playlistType === PLAYLIST_TYPES.TAG) ? contentDetail.creatorId : contentDetail.creator_id}
                                                        </td>
                                                    </tr>
                                                    <tr className="bullet_line">
                                                        <th>
                                                            <span>{t("COM_MIS_TEXT_EXPIRATION_DATE_P")}</span>
                                                        </th>
                                                        <td>
                                                            {contentDetail.expiredDate.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3")}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        ) : (
                                        <div className={"playlist_content_detail"} style={{height: 180, width: 330, margin: 20, position: "absolute"}}>
                                            <table>
                                                <colgroup>
                                                <col width="110px" />
                                                <col width="220px" />
                                                </colgroup>
                                                <tbody>
                                                    <tr className="bullet_line">
                                                        <th>
                                                            <span>{t("TEXT_CONTENT_NAME_P")}</span>
                                                        </th>
                                                        <td>
                                                            {<a href="#" onClick={() => this.openPlaylistDetail(contentDetail.contentId)} style={{whiteSpace:'normal', color: 'black'}}> {contentDetail.contentName}</a>}
                                                        </td>
                                                    </tr>
                                                    <tr className="bullet_line">
                                                        <th>
                                                            <span>{t("MIS_SID_CONTENT_TYPE")}</span>
                                                        </th>
                                                        <td>
                                                            {"Nested Playlist"}
                                                        </td>
                                                    </tr>
                                                    <tr className="bullet_line">
                                                        <th>
                                                        <span>{t('COM_TEXT_MODIFY_DATE_P')}</span>
                                                        </th>
                                                        <td>
                                                            <Moment format={getUserDateTimeFormat()}>{contentDetail.lastModifiedDate}</Moment>
                                                        </td>
                                                    </tr>
                                                    <tr className="bullet_line">
                                                        <th>
                                                            <span>{t("TEXT_CREATOR_P")}</span>
                                                        </th>
                                                        <td>
                                                            {contentDetail.creator_id}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> )
                                        }
                                    </div>
                                }
                            <div className="slide_img_wrap">
                                {!isInformationClick ? (
                                    <ContentThumbnail
                                        id={mainThumbnail}
                                        width={370}
                                        resolution={"ORIGIN"}
                                        height={220}
                                        isInformationClick = {isInformationClick}
                                        style={{ verticalAlign: "middle"}}/>
                                ) : (
                                    <ContentThumbnail
                                        id={mainThumbnail}
                                        width={370}
                                        resolution={"ORIGIN"}
                                        height={220}
                                        isInformationClick = {isInformationClick}
                                        style={{ verticalAlign: "middle", opacity: 0.5, zIndex: 1}}/>
                                    )
                                }
                                </div>
                            </div>
                            { contentDetail &&
                                <div style={{ width: 370, marginTop: 10 }}>
                                    <span style={{ marginRight: 200 }} onClick={this.onInformationClick}>
                                        <Checkbox name={t("COM_TEXT_INFORMATION_P")} checked={isInformationClick}/>
                                    </span>
                                    <WhiteButton id={"CONTENT_DOWNLOAD"}
                                                name={t("BUTTON_DOWNLOAD_P")}
                                                onClick={() => this.downloadContent(contentDetail, false)}
                                                disable={contentDetail.isSubPlaylist}
                                    />
                                </div>
                            }
                            <div style={{overflowY: 'hidden', marginTop: 10}}>
                                <ul style={{height: 120, minWidth: 300, width: thumbFileIds.length * 110, display: 'flex'}}>
                                    {
                                        thumbFileIds.map((thumbFileId,i) => {
                                            return (
                                                <li key={ i+"_"+thumbFileId } style={{height: 120, width: 108}} >
                                                    <div className={(contentIndex == i+1) ? "selected_thumbnail_border playlist_imgview_thumb_wrap":"playlist_imgview_thumb_wrap"}
                                                        onClick={() => this.onClickThumbnail(thumbFileId, i)}
                                                         style={{textAlign: 'center', verticalAlign: 'middle', position: 'relative'}}>
                                                        <ContentThumbnail id={thumbFileId} width={100} height={56} style={{ height: '100%', verticalAlign:'middle'}} />
                                                        {
                                                            isSubplaylists[i] &&
                                                            <div className={"linked"} ></div>
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            { contentDetail &&
                                <div style={{ width: 370, marginTop: 10, display: "flex" }}>
                                    <span>{`${contentIndex}/${thumbFileIds.length}`}</span>
                                </div>
                            }
                        </div>
                        <div className="playlist_detail playlistDetailView ml25 mb5" >
                            <SubTab tabs={subTabArr} selected={subTab} viewSize={3} onClick={this.onClickSubTab} />
                            { subTab === subTabs[0] && <PlaylistDetailTab playlist={playlist} onChangeDetail={this.onChangeDetail}/>}
                            { subTab === subTabs[1] &&
                            <VersionTable versions={playlistVersions}
                                          selectedVersionId={selectedVersionId}
                                          isChangeable={true}
                                          onChangeVersionId={this.onChangeVersionId} />

                            }
                            { subTab === subTabs_Tag[1] &&
                            <PlayListTagHistory tagHistory={tagHistory}
                                                tagHistoryLoading={tagHistoryLoading}/>
                            }
                            { subTab === subTabs[2] &&
                            <Category type={'tab'}
                                      optionStyle={categoryStyle}
                                      innerStyle={innerStyle}
                                      defaultCheckedKeys={this.state.selectedCategories}
                                      checkStrictly={true}
                                      defaultExpandAll={true}
                                      onCategoryCheck = {this.onCategoryCheck}
                            />
                            }
                        </div>
                    </div>
                }

            </MISDialog>

        )
    }

}
export default connect(
    state => ({
        menu: state.menu,
    }),
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id)=>dispatch(popupAction.closePopup(id)),
        clickSave: (id)=>dispatch(popupAction.updatePopup(id)),
        addTab: (tab)=>dispatch(menuAction.addTab(tab)),
        activeTab: (id) => dispatch(menuAction.activeTab(id)),
        loadContent: (id)=> dispatch(menuAction.loadContent(id)),
        loadPlaylist: (playlistInfo)=> dispatch(playlistAction.loadPlaylist(playlistInfo)),
        initEditPlaylist: (playlistInfo)=> dispatch(playlistAction.initEditPlaylist(playlistInfo)),
        openDetailView: (type) => dispatch(popupAction.openDetailView(type)),
        contentDownloading: (downloading) => dispatch(contentAction.contentDownloading(downloading)),
    })

)(withTranslation()(PlaylistDetailView));
