import React from 'react';
import {connect} from 'react-redux';
import {alertActions, userActions} from '../../actions';
import './LoginPage.css';
import Checkbox from "../../components/checkbox/Checkbox";
import {withTranslation} from "react-i18next";
import LOGO_SIGNIN from '../../images/img/logo_signin.png';
import Bullet from '../../images/icon/bullet_2.png';
import Gov from '../../images/icon/gov.png';
import Gov1 from '../../images/icon/gov_1.jpg';
import { LoadingPopup } from "../../components/loading/LoadingPopup";
import ResetPopup from "../../components/popup/login/ResetPopup";
import OtpSetupPopup from "../../components/popup/login/OtpSetupPopup";
import OtpLoginPopup from "../../components/popup/login/OtpLoginPopup";
import Select from "../../components/selectbox/Select";
import TextInput from "../../components/input/TextInput";
import {get, isEmpty, isNil, isObject, set} from 'lodash';
import {userService} from "../../services/user.service";
import {toastr} from 'helper/toastrIntercept'
import {checkemail, createQuery2, userIdLengthRangeCheck} from "../../helper";
import {userValidator} from "../../helper/userValidator";
import {getMixString} from "../../language/languageUtils";
import {getErrorMessage} from "../../helper/responseHandler";
import {settingService, commonService} from "../../services";
import PrivacyPolicyFooter from "../../components/link/PrivacyPolicyFooter";
import {settingConstants} from "../../constants";
import {userConstants} from '../../constants';
import {authServerUtil} from 'helper/authserver/authServerUtil';
import signinbgimage from '../../images/bg/sign_in.png'

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        const username = localStorage.getItem('magicInfoUserId') || '';
        //let authServerInfo = JSON.parse(localStorage.getItem('authServerInfo')) || {};
      
        this.MAIN_TEXT="main";
        this.SUB_TEXT="sub";
        this.isConfigLoaded=false;        
        this.state = {
            username: username,
            password: '',
            submitted: false,
            resetPopup: false,
            resetTitle : '',
            useRememberId: username !== undefined && username !== '' ? true : false,
            useSignUp: false,
            signUp: {
                show: false,
                email: '',
                jobPosition: '',
                mobileNum: '',
                organizationId: -1,
                password: '',
                rePassword: '',
                phoneNum: '',
                team: '',
                userId: '',
                userName: ''
            },
            error: {
                email: '',
                jobPosition: '',
                mobileNum: '',
                organizationId: '',
                password: '',
                rePassword: '',
                phoneNum: '',
                team: '',
                userId: '',
                userName: ''
            },
            organizations: [],
            privacyPolicyList: '',
            useAuthServer: true, //!isEmpty(authServerInfo.url)
            icpLeft: {
                certificate: false,
                icon: false,
                link: '',
                text: '',
                image: Gov
            },
            icpRight: {
                certificate: false,
                icon: false,
                link: '',
                text: '',
                image: Gov1       
            },
            customScreen:{
                use:false,
                imgSrc:'',
                mainText:{
                    value:'',
                    Color:'#323b46',
                    FontSize:30,
                    FontWeight:'bold'
                },
                subText:{
                    value:'',
                    Color:'#818181',
                    FontSize:16,
                    FontWeight:'normal'
                }
            } 
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);  
        this.fetchScreenConfig();       
        this.renderPrivacyPolicy();
        this.renderMfaInfo();
        this.fetchCommonSettings();                
    }

    componentDidMount() {
        const user = localStorage.getItem('user'); 
        if (user) {
            this.props.logout();
        }   
        this.checkAuthServerLogoutAndFetchInfo();
               
        this.fetchIcpInfo();
    }
    // [V9 RC #4] ICP certificate link add in Log-in screen
    fetchIcpInfo() {
        settingService.fetchIcpInfo().then(res => {
            const items = res.items;
            this.setState({
                    icpLeft:{
                        ...this.state.icpLeft,
                        certificate: (items.leftCertificate === 'true'),
                        icon: (items.leftIcon === 'true'),
                        link: items.leftLink,
                        text: items.leftText,
                    },
                    icpRight:{
                        ...this.state.icpRight,
                        certificate: (items.rightCertificate === 'true'),
                        icon: (items.rightIcon === 'true'),
                        link: items.rightLink,
                        text: items.rightText,
                    }

            });
        }).catch(e => {
            getErrorMessage(e);
        })
    }

    checkAuthServerLogoutAndFetchInfo() {
        const user = localStorage.getItem('user');
        if(authServerUtil.isAuthServerUsed()) {
            if(!authServerUtil.authServerLogout()) {
                const {t} = this.props;
                toastr.error(t('MIS_SID_CBAUG_AUTH_SERVER_LOGOUT_URL_NOT_FOUND') + '<br>' +
                    t('MIS_SID_CBAUG_USER_MAY_RELOGIN') + '<br>' + 
                    t('MIS_SID_CBAUG_CLEAR_BROWSER_COOKIES_TO_LOGOUT'), true);
                this._fetchAuthServerInfo();
            }
        }
        else {
            this._fetchAuthServerInfo();
        }
    }

    _fetchAuthServerInfo() {
        commonService.fetchCheckAuth().then(res => {
            if(res && res.url){
                const params = {
                    client_id: res.client_id,
                    client_secret: res.client_secret,
                    response_type: res.response_type,
                    redirect_uri: res.redirect_uri,
                }
                authServerUtil.setAuthServerUse(true);
                if(res.exit_uri) {
                    authServerUtil.setAuthServerExitUrl(res.exit_uri);
                } 
                const url = res.url + '?' + createQuery2(params);
                window.location.assign(url);
            } else {
                this.setState({useAuthServer: false});
            }
        }).catch(() => this.setState({useAuthServer: false}))
    }

    handleChange(e) {
        const { name, value } = e.target;
        if (name === 'username' && this.state.useRememberId) {
            localStorage.setItem('magicInfoUserId', value);
        }
        this.setState({ [name]: value });
    }

    handleRememberId(e) {
        const checked = e.target.checked;

        this.setState({
            useRememberId: checked
        })
        if (checked) {
            localStorage.setItem('magicInfoUserId', this.state.username);
        } else {
            localStorage.removeItem('magicInfoUserId');
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;
        const { login } = this.props;
        if (username && password) {
            login(username, password)
        }
    }

    showClosePopup(title) {
        const {resetPopup} = this.state;
        this.setState({resetPopup: !resetPopup});
        if(title !== undefined){
            this.setState({resetTitle: title});
        }else{
            this.setState({resetTitle: userConstants.RESET_PASSWORD});
        }
    }


    showCloseOtpSetupPopup() {

        const { initOtp } = this.props;
        initOtp();
    }


    showCloseOtpLoginPopup() {

        const { initOtp } = this.props;
        initOtp();
    }


    showSignUp() {
        const {signUp} = this.state;
        this.setState({signUp: 
            {...signUp, 
            show: !signUp.show,
            email: '',
            jobPosition: '',
            mobileNum: '',
            organizationId: -1,
            password: '',
            rePassword: '',
            phoneNum: '',
            team: '',
            userId: '',
            userName: ''}}, 
            ()=> {if (this.state.signUp.show){this.fetchOrganizations();}});
    }

    fetchCommonSettings() {
        settingService.fetchCommonSettings().then(res => {
            const items = res.items;
            this.setState({useSignUp:items.useSignUp});
        }).catch(e=> {
            getErrorMessage(e);
        })
    }

    fetchOrganizations() {
        if (this.state.organizations.length === 0) {
            userService.fetchOrganizationsForSignUp().then(
                r => {
                    if (r) {
                        const organizations = [];
                        r.items.map(
                            r=> {
                                organizations.push({title: r.organizationName, value: r.organizationId});
                            }
                        )
                        this.setState({
                            ...this.state,
                            organizations: organizations
                        })
                    }
                }
            ).catch(e=> {
                getErrorMessage(e);
            })
        }
    }

    handleSignUp(e) {
        const name = e.target.getAttribute('data-name');
        const value = e.target.value;
        this.setState({
            signUp: {
                ...this.state.signUp,
                [name]: value
            }
        }, ()=> {
            this.doValid(name, value);
        })
    }

    signUp() {
        const {signUp} = this.state;
        const {t} = this.props;
        const data = {
            ...signUp
        }
        let valid = true;
        Object.keys(signUp).map(
            key=> {
                if (this.doValid(key, signUp[key]) === false) {
                    valid = false;
                }
            }
        )
        if (valid) {
            userService.saveUsersForSignUp(data).then(
                r => {
                    if (r) {
                        toastr.success(t("MESSAGE_COMMON_REG_USER_P"));
                        this.showSignUp();
                    }
                }
            ).catch(e=> {
                toastr.error(getErrorMessage(e), true);
            });
        }
    }

    changeOrganization(e, value) {
        this.setState({
            ...this.state,
            signUp: {
                ...this.state.signUp,
                organizationId: parseInt(value, 10)
            }
        }, ()=> {
            this.doValid('organizationId', parseInt(value, 10));
        })
    }

    //010 9068 8345

    doValid(name, value) {
        const {signUp ,error} = this.state;
        let valid = true;
        const validEmail = (email) => {
            if (!isNil(signUp.email)) {
                if (checkemail(email)) {
                    error.email = "";
                } else {
                    valid = false;
                    error.email = "ALERT_INVALID_EMAIL_KR_MIS20";
                }
            } else {
                valid = false;
                error.email = "ALERT_INVALID_EMAIL_KR_MIS20";
            }
        }
        const validUserId = () => {
            if (!isNil(signUp.userId) && userIdLengthRangeCheck(signUp.userId)) {
                error.userId = "";
            } else {
                valid = false;
                error.userId = "MESSAGE_USER_ID_WRONG_LENGTH_P";
            }
        }
        const validPassword = () => {
            const {isValid, messageId} = userValidator('password', signUp.password);
            if(isValid){
                error.password = "";
            } else {
                valid = false;
                error.password = messageId;
            }
        }
        const validRePassword = () => {
            if (!isNil(signUp.rePassword) && signUp.rePassword !== '' && signUp.password === signUp.rePassword) {
                error.rePassword = "";
            } else {
                valid = false;
                error.rePassword = "MESSAGE_USER_DIFF_PASS_REPASS_P"
            }
        }
        const validUserName = () => {
            const {t} = this.props;
            if (isNil(signUp.userName) || signUp.userName == '' ) {
                valid = false;
                error.userName= t("MESSAGE_COMMON_CANT_NAME_SPACE_P");
            } else if(signUp.userName.length > 60){
                valid = false;
                error.userName = getMixString(["COM_SID_SCHEDULE_MIX_MAX_LENGTH", "60"]);
            } else {
                error.userName="";
            }
        }
        const validOrganizationId = () => {
            if (!isNil(signUp.organizationId) && signUp.organizationId > 0) {
                error.organization = "";
            } else {
                valid = false;
                error.organization = "MESSAGE_USER_SEL_ORGANIZATION_P";
            }
        }
        const validPhoneNum = () => {
            if (!isNil(signUp.phoneNum) && signUp.phoneNum.length > 60) {
                error.phoneNum = "MESSAGE_COMMON_MAX_60_P";
                valid = false;
            } else {
                error.phoneNum = "";
            }
        }
        const validMobileNum = () => {
            if (!isNil(signUp.mobileNum) && signUp.mobileNum.length > 60) {
                error.mobileNum = "MESSAGE_COMMON_MAX_60_P";
                valid = false;
            }  else {
                error.mobileNum = "";
            }
        }
        switch (name) {
            case 'userId':
                validUserId();
                break;
            case 'password':
            case 'rePassword':
                validPassword();
                validRePassword();
                break;
            case 'userName':
                validUserName();
                break;
            case 'email':
                validEmail(value);
                break;
            case 'organizationId':
                validOrganizationId();
                break;
            case 'mobileNum':
                validMobileNum();
                break;
            case 'phoneNum':   
                validPhoneNum();
                break;
            default:
                validUserId();
                validPassword();
                validRePassword();
                validUserName();
        }

        this.setState({
            error: error
        })
        return valid;
    }

    getSignInErrorMessage(error) {
        try {
            if(error.body){
                if(error.body.errorCode === "401007"){
                    
                    if(!isNil(error.body.errorDetails) && !isNil(error.body.errorDetails.retryCount)){
                        return getMixString(['MIS_SID_MIX_ENTERD_INCORRECT_PW_BLOCKED_TEMP', error.body.errorDetails.retryCount]);
                    }else{
                        return getMixString(['MIS_SID_MIX_ENTERD_INCORRECT_PW_BLOCKED_TEMP', '5']);
                    } 
                } else if(error.body.errorCode === "401000"){
                    return 'MIS_SID_20_THE_USER_ID_OR_PASSWORD_YOU_ENTERED_IS_INCORRECT';
                } else if(error.body.errorCode === "400725"){
                    return 'MIS_SID_20_THE_ID_IS_ALREADY_IN_USE';
                } else if (error.body.errorCode === '401008') {
                    return 'MIS_SID_20_THE_USER_IS_NOT_AUTHORIZED';
                }
            }
            return 'COM_IDS_MSG_UNEXPEXTED_ERROR';
        } catch(e) {
            return 'COM_IDS_MSG_UNEXPEXTED_ERROR';
        };
    }


    renderPrivacyPolicy() {

        localStorage.removeItem(settingConstants.PRIVACY_POLICY);

        settingService.fetchPrivacyPolicyList().then(res => {
            if(!isEmpty(res) && !isEmpty(res.items)) {
                this.setState({
                    privacyPolicyList: res.items
                })
                localStorage.setItem(settingConstants.PRIVACY_POLICY, JSON.stringify(res.items));
            }
        }).catch(err => console.log(err));

    }


    renderMfaInfo() {
        localStorage.removeItem(settingConstants.MFA);
        settingService.fetchMfaInfo().then(res => {
            if(!isEmpty(res) && !isEmpty(res.items)) {
                localStorage.setItem(settingConstants.MFA, JSON.stringify(res.items));
            }
        }).catch(err => console.log(err));
    }
    
    fetchScreenConfig() {

        userService.fetchScreenConfig().then(res => { 
                this.isConfigLoaded=true;
                this.setStateForCustomLogin(res.items);                
         }).catch(err => {
            this.isConfigLoaded=true;
             console.log(err)
            });
    }

    setStateForCustomLogin(data)
    {
        let customPro={...this.state.customScreen};
        customPro.use = data.useCustomLoginPageConfig===null?customPro.use:data.useCustomLoginPageConfig;
        if(customPro.use)
        {
            customPro.imgSrc= data.loginPageImageSrc === null ? customPro.imgSrc : data.loginPageImageSrc;
            this.setTextData(this.MAIN_TEXT,data,customPro);
            this.setTextData(this.SUB_TEXT,data,customPro);
        }
        this.setState({customScreen:{...customPro}});            
    }
    setTextData(type,data,customObj)
    {
        let prefix=type+"Text";
        if(data[prefix]!==null && data[prefix]!==undefined)
        {
            customObj[prefix].value=data[prefix];
        }
        let propArray=["Color","FontSize","FontWeight"];        
        for (var prop of propArray)
        {
            this.setSubValues(prefix,prop,data,customObj);
        }               
    }
    setSubValues(prefix,property,data,customObj)
    {
        let value=data[prefix+property];
        if(value!==null)
        {
            customObj[prefix][property]=value;
        }
    }

    
    // [V9 RC #4] ICP certificate link add in Log-in screen
    renderIcpInfo(data) {
            return (data.certificate && data.text)
            ? <>{(data.icon
                ?
                <>
                    <img src={data.image} />
                </>
                : <></>
            )}<a target="_blank" href={data.link}>{data.text}</a></>
            : null
    }
    getCustomLoginStyle()
    {
        return {
            mainText:this.getStyleByType(this.MAIN_TEXT),
            subText:this.getStyleByType(this.SUB_TEXT),
            backgroud:{
                backgroundImage:`url('${this.state.customScreen.imgSrc}')`
            }
        };
    }
    getStyleByType(type)
    {
        let cScreen=this.state.customScreen[type+"Text"];
        return {
            fontSize: cScreen["FontSize"]+"px",
            fontWeight: cScreen["FontWeight"],
            color:cScreen["Color"],
            lineHeight:cScreen["FontSize"]+"px",
            backgroundColor:!this.isConfigLoaded?"#CCCCCC":'',
            borderRadius:!this.isConfigLoaded?"20px":''        
        }
    }
    getLoginPageBackground(styles)
    {
        let refCustomScreen=this.state.customScreen;
        if(!this.isConfigLoaded)        
        {
            return {};
        }
        if(!refCustomScreen.use)
        {
            return {
                backgroundImage:`url('${signinbgimage}')`
            };
        }
        return (refCustomScreen.imgSrc!=="") ? styles.backgroud:{}
    }
    getRenderingText(type)
    {
        let refCustomScreen=this.state.customScreen;
        if(!this.isConfigLoaded)        
        {
            return '';
        }
        if (!refCustomScreen.use)        
        {
            return this.getDefaultTextByType(type);
        }
        return refCustomScreen[type+"Text"].value;
    }
    getDefaultTextByType(type)
    {
        const { t } = this.props;
        if(type===this.MAIN_TEXT)
        {
            return  t("MIS_SID_20_A_POWERFUL_AND_INTUITIVE_CONTENT_MANAGEMENT_SOLUTION");
        }
        else if (type===this.SUB_TEXT)
        {
            return t("MIS_SID_20_EQUIPS_ADMINISTRATORS_MANAGE_AND_SCHEDULE_FABULOUS_MEDIA_CONTENT");
        }
        return '';
    }
    getTextParentStyle(type)
    {
        let style={
            textAlign:'center',
            paddingBottom:'0px'           
        }
        if(type===this.MAIN_TEXT)
        {
            style.paddingBottom="20px";
        }       
        return style;
    }

    render() {

        const { username, password, resetPopup, resetTitle, otpSetupPopup, otpLoginPopup, signUp, organizations, useAuthServer, icpLeft, icpRight } = this.state;
        const { t, error, mfa, authentication } = this.props;
	    const {customScreen} = this.state;

        const styles = this.getCustomLoginStyle();

        return (
            useAuthServer ? 
            <></>
            :
            <div className="container bg">
                {
                    authentication !== undefined && authentication.logginIn !== undefined && authentication.logginIn &&
                        <LoadingPopup/>
                }
                {
                    resetPopup !== undefined && resetPopup &&
                    <ResetPopup type={resetTitle}  userId={username}
                                closePopup={()=>this.showClosePopup(userConstants.RESET_PASSWORD)} {...this.props}/>
                }
                {
                    mfa !== undefined && mfa.type === userConstants.LOGIN_SETUP_OTP &&
                    <OtpSetupPopup mfa={mfa}
                                   closePopup={()=>this.showCloseOtpSetupPopup()} {...this.props}/>
                }

                {
                    mfa !== undefined && mfa.type === userConstants.LOGIN_REQUIRED_OTP &&
                    <OtpLoginPopup mfa={mfa} resetPopup={()=>this.showClosePopup(userConstants.RESET_OTP)}
                                   closePopup={()=>this.showCloseOtpLoginPopup()} {...this.props}/>
                }

                <div className="signin_box">
                    <div className="signin_wrap">
                        <form autoComplete = "off" onSubmit={this.handleSubmit}>
                        <div>
                            <div className="logoDiv hAligncenter"><img src={LOGO_SIGNIN} /></div>
                            <div className="signin_area">
                                <input  autoComplete="off" type="text" id="loginId" name="username" className="sign_input" placeholder={t("COM_DID_ADMIN_USER_USERID")} value={username} required onChange={this.handleChange} />
                                <input  autoComplete="off" id="loginPass" name="password" type="password" className="sign_input underline" placeholder={t("TEXT_PASSWORD_P")} value={password} required onChange={this.handleChange}/>
                                <div id="loginErrorWrap" style={{color:'red', marginTop:'10px'}}>
                                    {
                                        error !== undefined && 
                                        t(this.getSignInErrorMessage(error))
                                    }
                                </div>
                            </div>
                            <div>
                                <button id="loginBtn" className="sign_btn" type="submit">{t("COM_TV_SID_SIGN_IN")}</button>
                            </div>
                            <div className="checked_area">
                                <Checkbox id="loginRemember" name={t("COM_HTS_SID_REMEMBER_MY_ID")} onChange={(e)=>this.handleRememberId(e)} checked={this.state.useRememberId}/>
                            </div>                            
                        </div>
                        </form>
                        <div className="signup_go_wrap">
                                <a href="#" id="resetPasswordBtn" onClick={()=>this.showClosePopup(userConstants.RESET_PASSWORD)}>{t("TEXT_RESET_PASSWORD_P")}</a><br/>
                                {
                                    this.state.useSignUp && 
                                    <><a href="#" id="singUpBtn" onClick={()=>this.showSignUp()}>{t("BUTTON_SIGN_UP_P")}</a><br/></>
                                }
                         </div>
                    </div>
  
                    <div className="sign_bg_base vAlignmiddle hAligncenter" style={this.getLoginPageBackground(styles)}>
                        <div className="sign_mainimg_box" style={{display: !signUp.show ? '':'none'}}>
                            <div style={{width:'600px'}}>
                                <div style={this.getTextParentStyle(this.MAIN_TEXT)}>
                                    <span className="wrapAll" style={styles.mainText} >{this.getRenderingText(this.MAIN_TEXT)}</span>
                                </div>
                                <div style={this.getTextParentStyle(this.SUB_TEXT)}>
                                    <span  className="wrapAll" style={styles.subText}> {this.getRenderingText(this.SUB_TEXT)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="signup_wrap" style={{display: signUp.show ? '':'none', autoflow:'auto'}}>
                        <div>
                            <h1>{t("BUTTON_SIGN_UP_P")}</h1>

                            <div className="signup_checking_area">
                                <h3>{t("COM_SID_BASIC_INFORMATION")}<span>{t("MIS_SID_20_A_REQUIRED_ITEM_PLEASE_ENTER_A_VALUE")}</span></h3>
                                <table>
                                    <colgroup><col width="150px" /><col width="" /></colgroup>
                                    <tbody>
                                        <tr>
                                            <th>{t("COM_DID_ADMIN_USER_USERID")}<img src={Bullet} /></th>
                                            <td>
                                                <TextInput autoComplete={"off"} placeholder={t("COM_DID_ADMIN_USER_USERID")} width={200} propertyName={'userId'} value={this.state.signUp.userId} onChange={(e)=>this.handleSignUp(e)} error={this.state.error.userId !== ''}/>
                                                <div id="signUpuserIdErrorDiv" style={{display: this.state.error.userId !== '' ? '' : 'none'}}>
                                                    <span className="error_txt2" id="signUpuserIdErrorMsg">
                                                        {t(this.state.error.userId)}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("TEXT_PASSWORD_P")}<img src={Bullet} /></th>
                                            <td>
                                                <TextInput  autoComplete={"off"} type={'password'}  placeholder={t("TEXT_PASSWORD_P")} width={200} propertyName={'password'} value={this.state.signUp.password} onChange={(e)=>this.handleSignUp(e)} error={this.state.error.password !== ''}/>
                                                <div id="signUpPasswordErrorDiv" style={{display: this.state.error.password !== '' ? '' : 'none'}}>
                                                    <span className="error_txt2" id="signUpPasswordErrorMsg">
                                                        {t(this.state.error.password)}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("SETUP_NEW_STRING5_P")}<img src={Bullet} /></th>
                                            <td>
                                                <TextInput autoComplete={"off"} type={'password'} placeholder={t("SETUP_NEW_STRING5_P")} width={200} propertyName={'rePassword'} value={this.state.signUp.rePassword} onChange={(e)=>this.handleSignUp(e)} error={this.state.error.rePassword !== ''}/>
                                                <div id="signUpRePasswordErrorDiv" style={{display: this.state.error.rePassword !== '' ? '' : 'none'}}>
                                                    <span className="error_txt2" id="signUpRePasswordErrorMsg">
                                                        {t(this.state.error.rePassword)}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("COM_TEXT_USER_NAME_P")}<img src={Bullet} /></th>
                                            <td>
                                                <TextInput autoComplete={"off"} width={200} propertyName={'userName'} value={this.state.signUp.userName} onChange={(e)=>this.handleSignUp(e)} error={this.state.error.userName !== ''}/>
                                                <div id="signUpUserNameErrorDiv" style={{display: this.state.error.userName !== '' ? '' : 'none'}}>
                                                    <span className="error_txt2" id="signUpUserNameErrorMsg">
                                                        {this.state.error.userName}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("COM_TV_SID_EMAIL")}<img src={Bullet} /></th>
                                            <td>

                                                <TextInput width={200} placeholder="example@email.com" propertyName={'email'} value={this.state.signUp.email} onChange={(e)=>this.handleSignUp(e)} error={this.state.error.email !== ''}/>
                                                <div id="signUpEmailErrorDiv" style={{display: this.state.error.email !== '' ? '' : 'none'}}>
                                                    <span className="error_txt2" id="signUpEmailErrorMsg">
                                                        {t(this.state.error.email).replace('<br>', ' ')}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("TEXT_MOBILE_NUM_P")}</th>
                                            <td>
                                                <TextInput width={200} placeholder={t("TEXT_MOBILE_NUM_P")} propertyName={'mobileNum'} value={this.state.signUp.mobileNum} onChange={(e)=>this.handleSignUp(e)} error={this.state.error.mobileNum !== ''}/>
                                                <div id="signUpMobileNumErrorDiv" style={{display: this.state.error.mobileNum !== '' ? '' : 'none'}}>
                                                    <span className="error_txt2" id="signUpMobileNumErrorMsg">
                                                        {t(this.state.error.mobileNum)}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("MIS_SID_20_HOME_WORK_PHONE")}</th>
                                            <td>
                                                <TextInput width={200} placeholder={t("MIS_SID_20_HOME_WORK_PHONE")} propertyName={'phoneNum'} value={this.state.signUp.phoneNum} 
                                                onChange={(e)=>this.handleSignUp(e)} error={this.state.error.phoneNum !== ''}/>
                                                <div id="signUpPhoneNumErrorDiv" style={{display: this.state.error.phoneNum !== '' ? '' : 'none'}}>
                                                    <span className="error_txt2" id="signUpPhoneNumErrorMsg">
                                                        {t(this.state.error.phoneNum)}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="signup_checking_area">
                                <h3>{t("TEXT_ORGANIZATION_INFO_P")}</h3>
                                <table>
                                    <colgroup><col width="150px" /><col width="" /></colgroup>
                                    <tbody>
                                        <tr>
                                            <th>{t("TABLE_ORGANIZATION_P")}<img src={Bullet} /></th>
                                            <td>
                                                {
                                                    this.state.organizations !== undefined && this.state.organizations.length > 0
                                                        && <Select selects={this.state.organizations} value={this.state.signUp.organizationId} onChange={(e, value)=>this.changeOrganization(e, value)} multiLang={false}/>
                                                }
                                                <div id="signUpOrganizationErrorDiv" style={{display: this.state.error.organization !== '' ? '' : 'none'}}>
                                                    <span className="error_txt2">
                                                        {t(this.state.error.organization)}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("DID_ADMIN_USER_TEAM")}</th>
                                            <td>
                                                <TextInput width={200} maxLength={60} placeholder={t("DID_ADMIN_USER_TEAM")} propertyName={'team'} value={this.state.signUp.team} onChange={(e)=>this.handleSignUp(e)}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("DID_ADMIN_USER_POSITION")}</th>
                                            <td>
                                                <TextInput width={200} maxLength={60} placeholder={t("DID_ADMIN_USER_POSITION")} propertyName={'jobPosition'} value={this.state.signUp.jobPosition} onChange={(e)=>this.handleSignUp(e)}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <button className="signup mr8" id="signUpSubmitBtn" onClick={()=>this.signUp()}>{t("BUTTON_SIGN_UP_P")}</button>
                                <button id="signUpCancelBtn" className="cancel" onClick={()=>this.showSignUp()}>{t("BUTTON_CANCEL_P")}</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="copyRightDiv" className="copyRight" style={{ display: signUp.show ? 'none' : '', position: 'absolute', left: '19%',width:'81%', bottom: '0px', zIndex: 90, background: 'none',height:'80px' }}>
                        <div style={{ textAlign: 'center' }}>Copyright @ 2009 - 2022 Samsung Electronics Co., Ltd.</div>
                        <div style={{ textAlign: 'center' }}>{window.globalConfig.magicInfoFrontEndVersion}&nbsp;|&nbsp;
                <PrivacyPolicyFooter login={false} privacyPolicyList={this.state.privacyPolicyList} />
                            <a href="https://displaysolutions.samsung.com/about-us/contact-us" target="_blank" style={{ color: '#609be8' }}>{t("MIS_SID_CONTACT_US")}</a>
                        </div>
                        {/* [V9 RC #4] ICP certificate link add in Log-in screen */}
                        <div className="gov" style={{overflow: 'auto' }}>
                            {this.renderIcpInfo(icpLeft)}&nbsp;&nbsp;&nbsp;
                            {this.renderIcpInfo(icpRight)}
                        </div>
                    </div>

            </div>

        );
    }
}
export default connect(
    state => ({
        authentication: state.authentication,
        error: state.users.error,
        mfa : state.users.mfa
    }),
    dispatch => ({
        logout: ()=> dispatch(userActions.logout()),
        initOtp: ()=> dispatch(userActions.initOtp()),
        login: (userId, password)=> dispatch(userActions.login(userId, password)),
        clear: () => dispatch(alertActions.clear())
    })
)(withTranslation()(LoginPage));
