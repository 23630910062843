import React, {useEffect, useState} from "react";
import WhiteButton from "../../components/button/WhiteButton";
import Category from "../../components/category/Category";
import {useTranslation} from "react-i18next";
import {commonService, settingService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import * as ReactDOM from "react-dom";
import {jsonToTreeForCategory, validateSpecialChar} from "../../helper";
import {popupAction} from "../../actions";
import {commonConstants} from "../../constants";
import {useDispatch} from "react-redux";
import {getErrorMessage} from "../../helper/responseHandler";

const CategoryManagement = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [reLoadedCategories, setReLoadedCategories] = useState([]);
    const [checkedCategory, setCheckedCategory] = useState([]);
    const [initKey, setInitKey] = useState(0);
    let newDom = undefined;

    useEffect(() => {
        newDom =  undefined;
        return () => {
            newDom =  undefined;
            const treediv = document.getElementsByClassName("category_tree_div");
            if(treediv != undefined && treediv.length > 0){
                treediv[0].removeEventListener('click', handleClickOutsideForRename, true);
            }
            
        };
    }, []);

    const onCategoryCheck =(checked, treeNode)=>{

        if(setCheckedCategory && treeNode.checked){
            checkedCategory.push({key: treeNode.node.props.eventKey, Elem : treeNode.nativeEvent.target.parentNode});
            setCheckedCategory(checkedCategory)
        }else if (setCheckedCategory && !treeNode.checked){
            const currentChecked = checkedCategory
                .filter( category => category.key != treeNode.node.props.eventKey )
            setCheckedCategory(currentChecked)
        }
    }

    const addCategory = () => {
        if (checkedCategory.length == 1) {

            const currentGroup = checkedCategory[0].Elem;
            if (ReactDOM.findDOMNode(currentGroup).querySelector(".rc-tree-child-tree") !== null) {
                const childTree = ReactDOM.findDOMNode(currentGroup).querySelector(".rc-tree-child-tree");
                const element = {
                    type: 'li',
                    props: {
                        id: 'temp_li',
                        children: [
                            {
                                type: 'input',
                                props: {type: 'text', className:'tempGroupName', value:'new node', onKeydown: (e) => handleKeyDownForCreate(e, childTree)}
                            }
                        ]
                    }
                }
                renderEleInDom(element, childTree);
                newDom = childTree;
            } else {
                const childTree = ReactDOM.findDOMNode(currentGroup);
                const element = {
                    type: 'ul',
                    props: {
                        className: 'rc-tree-child-tree rc-tree-child-tree-open',
                        children: [
                            {
                                type: 'li',
                                props: {
                                    id: 'temp_li',
                                    children: [
                                        {
                                            type: 'input',
                                            props: {type: 'text',className:'tempGroupName', value:'new node',onKeydown: (e) => handleKeyDownForCreate(e, childTree)}
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
                renderEleInDom(element, childTree);
                newDom = childTree;
            }
            document.addEventListener('click', handleClickOutside, true);
            ReactDOM.findDOMNode(currentGroup).querySelector('.tempGroupName').focus();
        }else{
            toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
        }

    }

    const renderEleInDom = (element, parentDom) => {
        const { type, props } = element;
        const dom = document.createElement(type);

        const isListener = name => name.startsWith("on");
        Object.keys(props).filter(isListener).forEach(name => {
            const eventType = name.toLowerCase().substring(2);
            dom.addEventListener(eventType, props[name]);
        });

        const isAttribute = name => !isListener(name) && name != "children";
        Object.keys(props).filter(isAttribute).forEach(name => {
            dom[name] = props[name];
        });

        const childElements = props.children || [];
        childElements.forEach(childElement => renderEleInDom(childElement, dom));
        parentDom.appendChild(dom);
        dom.focus();
    }

    const handleClickOutsideForRename = (e) => {
        if(e.target.classList.contains("tempGroupName")){
            return;
        }
        const tempLi = ReactDOM.findDOMNode(newDom).querySelector("#temp_li");
        if(tempLi == undefined)
            return;

        const groupName = tempLi.value;
        const removeLi = () => {
        const tempLi = ReactDOM.findDOMNode(newDom).querySelector(".tempGroupName");
            if (tempLi !== null) {
                tempLi.remove();
            }
        }
        if(groupName.trim() == '') {
            toastr.error(t('MIS_MESSAGE_COMMON_CANT_TEXT_SPACE_P'));
            return
        }
        if(!validateSpecialChar(groupName)) {
            toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
            return
        }
        if(groupName.length>100){
            toastr.error(t('COM_SID_SCHEDULE_MIX_MAX_LENGTH').replace('<<A>>',100))
            return
        }

        const currentGroupSpan = ReactDOM.findDOMNode(tempLi).parentNode.querySelector('.rc-tree-node-content-wrapper');
        currentGroupSpan.setAttribute('style', '');

        const currentGroupSpanTitle = ReactDOM.findDOMNode(tempLi).parentNode.querySelector('.rc-tree-title');
        currentGroupSpanTitle.setAttribute('style', '');

        renameCategoryReq(tempLi.categoryId, groupName, removeLi);
    };

    const handleClickOutside = (e) => {
        if(e.target.classList.contains("tempGroupName")){
            return;
        }
        const tempLi = ReactDOM.findDOMNode(newDom).querySelector("#temp_li");
        if(tempLi == undefined)
            return;

        const groupName = tempLi.querySelector("input").value
        const pGroupId =  checkedCategory[0].key;
        const removeLi = () => {
            if (tempLi !== null)
                tempLi.remove();
        }

        if(!validateSpecialChar(groupName)) {
            toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
            removeLi();
            return
        }
        if(groupName.length>100){
            toastr.error(t('COM_SID_SCHEDULE_MIX_MAX_LENGTH').replace('<<A>>',100))
            removeLi();
            return
        }
        addCategoryReq(groupName, pGroupId,removeLi);
    };

    const handleKeyDownForCreate = (e, childTree) => {
        const removeLi = () => {
            const tempLi = ReactDOM.findDOMNode(childTree).querySelector("#temp_li");
            if (tempLi !== null)
                tempLi.remove();
        }
        if (e.keyCode === 13) {
            const groupName =  e.target.value
            const pGroupId =  checkedCategory[0].key;
            
            if(!validateSpecialChar(groupName)) {
                toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
                return
            }

            if(groupName.length>100){
                toastr.error(t('COM_SID_SCHEDULE_MIX_MAX_LENGTH').replace('<<A>>',100))
                return
            }
            addCategoryReq(groupName, pGroupId,removeLi);
        } else if (e.keyCode === 27) {
            removeLi();
        }
    }

    const handleKeyDownForChange = (e, childTree,categoryId) => {
        const removeLi = () => {
            const tempLi = ReactDOM.findDOMNode(childTree).querySelector(".tempGroupName");
            if (tempLi !== null) {
                tempLi.remove();
            }
        }
        if (e.keyCode === 13) {
            const value = e.target.value;
            let valid = validateSpecialChar(value);
            if(!valid) {
                toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
                return;
            }
            if(value.trim() == '') {
                toastr.error(t('MIS_MESSAGE_COMMON_CANT_TEXT_SPACE_P'));
                return;
            }
            if(value.length>100){
                toastr.error(t('COM_SID_SCHEDULE_MIX_MAX_LENGTH').replace('<<A>>',100));
                return;
            }

            const currentGroupSpan = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-node-content-wrapper');
            currentGroupSpan.setAttribute('style', '');

            const currentGroupSpanTitle = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-title');
            currentGroupSpanTitle.setAttribute('style', '');

            renameCategoryReq(categoryId, value, removeLi);

        } else if (e.keyCode === 27) {
            const currentGroupSpan = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-node-content-wrapper');
            currentGroupSpan.setAttribute('style', '');

            const currentGroupSpanTitle = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-title');
            currentGroupSpanTitle.setAttribute('style', '');

            removeLi();
        }
    }
    const addCategoryReq = (groupName, pGroupId,removeLi) => {
        settingService.addCategory(encodeURIComponent(groupName),pGroupId)
            .then( () => {
                reLoadCategory();
        }).finally(()=>{
            removeLi();
            document.removeEventListener('click', handleClickOutside, true);
        })
    }

    const renameCategoryReq = (categoryId, value, removeLi) => {
        settingService.renameCategory(categoryId,value)
            .then( () => {
                reLoadCategory();
                toastr.success(t("MIS_TEXT_FTP_MODIFIED_P"));
            })
            .catch(e => toastr.error(getErrorMessage(e)))
            .finally(()=>{
                removeLi();
                const treediv = document.getElementsByClassName("category_tree_div");
                if(treediv != undefined && treediv.length > 0){
                    treediv[0].removeEventListener('click', handleClickOutsideForRename, true);
                }
                setInitKey(initKey + 1);
            })
    }

    const deleteCategory = () =>{
        if (checkedCategory.length === 1) {
            console.log(checkedCategory);
            const categoryId = checkedCategory[0].key;

            const currentChecked = checkedCategory.filter( category => category.key != categoryId )
            setCheckedCategory(currentChecked)

            dispatch(popupAction.addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                title: t("COM_BUTTON_CONFIRM"),
                message: t("ALERT_WANT_DELETE"),
                onClickYes: () => handleDelete(categoryId),
                onClose: () => {
                    dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                    setInitKey(initKey + 1);
                },
            }));

        }else {
            toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
        }
    }

    const handleDelete = (categoryId) => {
        settingService.deleteCategory(categoryId)
            .then(res =>{
                toastr.success(t("TEXT_SUCCESS_P"))
                reLoadCategory();
            })
            .catch(e => toastr.error(getErrorMessage(e)))
            .finally(() =>{
                setInitKey(initKey + 1);
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
            });
    }

    const renameCategory = () =>{
        if (checkedCategory.length == 1) {
            const categoryId = checkedCategory[0].key;
            let currentGroup = checkedCategory[0].Elem;
            let childTree
            let groupName
            if (ReactDOM.findDOMNode(currentGroup).querySelector(".rc-tree-child-tree") !== null) {
                childTree = ReactDOM.findDOMNode(currentGroup).querySelector(".rc-tree-child-tree");
                currentGroup = ReactDOM.findDOMNode(currentGroup).querySelector(".rc-tree-node-content-wrapper");
                groupName = currentGroup.textContent
                childTree = ReactDOM.findDOMNode(childTree).parentNode.querySelector(".rc-tree-node-content-wrapper");
                const element = {
                    type: 'input',
                    props: {
                        id: 'temp_li',
                        type: 'text',
                        className: 'tempGroupName',
                        value: groupName,
                        categoryId: categoryId, 
                        onKeydown: (e)=>handleKeyDownForChange(e, currentGroup.parentNode, categoryId)
                        /*onblur: (e)=>updateGroupBlur(e, currentGroup)*/
                    }
                }
                renderEleInDom(element, childTree);
                newDom = childTree;
            }else{
                childTree = ReactDOM.findDOMNode(currentGroup);
                groupName = currentGroup.textContent
                const element = {
                    type: 'input',
                    props: {
                        id: 'temp_li',
                        type: 'text',
                        className: 'tempGroupName',
                        value: groupName,
                        categoryId: categoryId, 
                        onKeydown: (e)=>handleKeyDownForChange(e, childTree, categoryId)
                        /*onblur: (e)=>updateGroupBlur(e, currentGroup)*/
                    }
                }
                renderEleInDom(element, childTree);
                newDom = childTree;
            }


            const currentChecked = checkedCategory.filter( category => category.key != categoryId )
            setCheckedCategory(currentChecked)

            ReactDOM.findDOMNode(childTree).querySelector('.tempGroupName').focus();

            if (ReactDOM.findDOMNode(childTree).querySelector(".rc-tree-child-tree") !== null) {
                const currentGroupSpan = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-title');
                if (currentGroupSpan !== null) {
                    currentGroupSpan.setAttribute('style', 'display: none');
                }
            }else{
                const currentGroupSpan = ReactDOM.findDOMNode(childTree).querySelector('.rc-tree-title');
                currentGroupSpan.setAttribute('style', 'display: none');
            }

            const treediv = document.getElementsByClassName("category_tree_div");
                if(treediv != undefined && treediv.length > 0){
                    treediv[0].addEventListener('click', handleClickOutsideForRename, true);
                }
           
        }else {
            toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
        }
    }


    const reLoadCategory = () =>{
        commonService.fetchCategory().then(
            res=> {
                res.items.sort(function(a, b){
                    return a.groupName < b.groupName ? -1 : a.groupName > b.groupName ? 1 : 0;
                });
                setReLoadedCategories(jsonToTreeForCategory(res.items))
                setCheckedCategory([]);

            }
        ).finally(()=>{
            setInitKey(initKey+1)
            }
        );
    }


    return (
        <div style={{width: '100%', display : props.currContent === 'CATEGORY_MANAGEMENT' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"CATEGORY_ADD"} name={t("COM_BUTTON_ADD")} onClick={()=>addCategory()} />
                    <WhiteButton id={"CATEGORY_DELETE"} name={t("COM_BUTTON_DELETE")} onClick={()=>deleteCategory()}/>
                    <WhiteButton id={"CATEGORY_RENAME"} name={t("BUTTON_CHANGE_NAME_P")} onClick={()=>renameCategory()}/>
                </div>

            </div>
            <div style={{border: '1px solid #e7e7e7', margin: '20px 24px 0 24px'}}>
                <Category {...props} key = {initKey} type='tab' checkStrictly={true}  onCategoryCheck={onCategoryCheck} reLoadedCategories ={reLoadedCategories}/>
            </div>
        </div>
    )
};
export default CategoryManagement;