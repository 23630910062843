import React, {useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import {useTranslation} from 'react-i18next';
import {settingService, userService} from "../../services";
import Pagination from "../../components/table/Pagination";
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {commonConstants, contentConstants, userConstants} from "../../constants";
import {connect} from "react-redux";
import {menuAction, popupAction} from "../../actions";
import {isNil, merge, snakeCase} from "lodash";
import {toastr} from 'helper/toastrIntercept';
import "./User.css"
import fileDownload from "js-file-download";
import {
    dateToString,
    getPageSize,
    useCheckRefWithSelectedCnt as useCheckRef,
    useFilter,
    usePrevious
} from "../../helper";
import {checkButtonDisplayWithAuthority, convertSortColumnId} from "../../components/popup/user/common/userCommons";
import {useMISOpt} from "../../components/misopt";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";
import {updateCache} from "../../helper/cache/tableCache";
import {ConfirmPassword} from "../setting/ConfirmPassword";
import {getUnRecognizedData} from "../../helper/utils";

const AllUser = (props) => {
    const {t} = useTranslation();

    const [style, setStyle] = useState({
        height: '762px'
    });
    const userNeedAuth = JSON.parse(localStorage.getItem("userNeedAuth"));

    const [reconfirm, setReconfirm] = useState((userNeedAuth)?false:true);

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter,
        mode: props.mode
    });

    const [data, setData] = useState({
        selected: [],
        selectAll: false,
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0
    });

    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted, groupId, organizationId, keyword} = filter;
    const {addPopup, closePopup, updateSubMenu, reloadGroups} = props;

    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const isServerAdmin = localStorage.getItem('user') === null ? false : JSON.parse(localStorage.getItem('user')).serverAdmin;
    const {getAuthority} = useMISOpt();
    const userAuthority = getAuthority("USER");
    const logInGroupId = localStorage.getItem('user') === null ? -1 : JSON.parse(localStorage.getItem('user')).groupId;
    const logInUserId = localStorage.getItem('user') === null ? -1 : JSON.parse(localStorage.getItem('user')).id;
    const logInRoleName = localStorage.getItem('user') === null ? -1 : JSON.parse(localStorage.getItem('user')).roleName;
    const isMultiOrganizationManager = localStorage.getItem('user') === null ? false : JSON.parse(localStorage.getItem('user')).isMultiOrganizationManager;

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        if(reconfirm === true) {
            fetchData();
        }
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    const preOrganizationId =  usePrevious(props.organizationId);
    const preGroupId = usePrevious(props.groupId);
    const preCurrContent = usePrevious(props.currContent);

    useEffect(() => {

        if (
            (preCurrContent !== undefined && preCurrContent !== props.currContent) ||
            (preOrganizationId !== undefined && preOrganizationId !== props.organizationId) ||
            (preGroupId !== undefined && preGroupId !== props.groupId)
        ) {
            if (props.mode === 'GROUPED' && props.groupId === undefined) {
                return;
            }
            setFilter({
                ...filter,
                page: 0,
                pageSize: getPageSize('user', 100),
                mode: props.mode,
                groupId: props.mode === 'ALL' ? '' : props.groupId,
                organizationId: props.mode === 'ALL' ? '' : props.organizationId,
                searchText: '',
                roleName: '',
                startCreatedDate: '',
                endCreatedDate: '',
                isSearching: false
            });
        }

    }, [props.organizationId, props.groupId]);

    useEffect(() => {
        //[V9 RC #12][6. Fetch users data only after successful authorization of current user]
        if(reconfirm === true && (!props.cache.isLoaded || filter.isFetched )) {
            fetchData();
        }
    }, [filter]);

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    };

    const initFilterValues = () => {
        filter.page = 0;
        filter.pageSize = getPageSize('user', 100);
        filter.keyword= '';
        filter.roleName = '';
        filter.startCreatedDate = '';
        filter.endCreatedDate = '';
        filter.groupId=  props.mode === 'ALL' ? '' : props.groupId;
        filter.organizationId = props.mode === 'ALL' ? '' : props.organizationId;
        filter.isSearching = false;
    };

    

    const fetchData = () => {
        setData({...data, loading: true});

        const {page, pageSize, keyword, sorted: [{id, desc}], groupId, organizationId, roleName, startCreatedDate, endCreatedDate} = filter;

        userService.fetchUserFilter({
            startIndex: page === 0 ? page + 1 : page * pageSize + 1,
            pageSize: pageSize,
            userStatus: groupId === '' ? 'ALL' : 'GROUPED',
            groupId: groupId,
            organizationId: organizationId,
            roleName: roleName,
            searchText: keyword,
            startCreatedDate: dateToString(startCreatedDate),
            endCreatedDate: dateToString(endCreatedDate),
            sortColumn: snakeCase(convertSortColumnId(id)),
            sortOrder: desc === true ? 'DESC' : 'ASC'
        }).then(res => {
            res.items.map(item => {
                if (item.groupName === userConstants.ORGAN_NAME_ADMINISTRATORS) {
                    item.groupName = t('TEXT_ROOT_GROUP_NAME_P');
                }
            });
            setData({
                ...data,
                selected: [],
                selectAll: false,
                loading: false,
                items: res.items,
                totalCount: res.totalCount,
                pages: Math.ceil(res.totalCount / res.pageSize)
            })
            updateCache('USER', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent)
        }).catch(() => {
            setData({...data, loading: false});
            //setFilter({...filter, organizationId: '', groupId: '', userStatus: 'ALL'});
        }).finally(
            closePopup(commonConstants.LOADING_POPUP)
        );
    };

    const onClickDetailSearch = searchDetail => {
        setFilter({...filter,
            organizationId: filter.mode === 'GROUPED' ? filter.organizationId : searchDetail.organizationId,
            groupId: filter.mode === 'GROUPED' ? filter.groupId : searchDetail.groupId,
            roleName: searchDetail.roleName,
            startCreatedDate: searchDetail.date.startDate === undefined ? '' : searchDetail.date.startDate,
            endCreatedDate: searchDetail.date.endDate === undefined ? '' : searchDetail.date.endDate,
            isSearch: true,
            page: 0
        });
    };

    const onClickExport = (docType) => {
        const {keyword, sorted: [{id, desc}], startCreatedDate, endCreatedDate} = filter;

        let defaultObj = {
            searchText: keyword,
            groupType: "APPROVED",
            sortOrder: desc === true ? 'DESC' : 'ASC',
            sortColumn: convertSortColumnId(id),
            startCreatedDate: dateToString(startCreatedDate),
            endCreatedDate: dateToString(endCreatedDate)
        };

        let groupObj = {};
        if (organizationId !== '' && groupId === '') {
            groupObj = {organizationId: organizationId};
        } else if (organizationId !== '' && groupId !== '') {
            groupObj = (parseInt(organizationId) === parseInt(groupId)) ? {organizationId: organizationId} : {organizationId: organizationId, groupId: groupId};
        }

        userService.fetchUserFileDownload(docType, {...defaultObj, ...groupObj}).then(res => {
            let fileName = '';
            if (docType === 'EXCEL') {
                fileName = 'UserList.xls';
            }
            fileDownload(res.blob, fileName);
        }).catch(err => {
            err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
        });
    };

    const _setChangeOrgBtnStatus = () => {
        return selected.current.filter(s => items[s] && items[s].userInfo.userId === logInUserId).length !== 0;
    };

    const handleRemoveUsers = (reasonText) => {
        const usersOfAdmin = selected.current.filter(s => items[s].roleName === userConstants.ROLE_NAME_ADMINISTRATOR);
        if (usersOfAdmin.length > 0 && isServerAdmin === false) {
            toastr.error(t('MIS_MESSAGE_COMMON_NOT_SERVER_ADMINISTRATOR_P'));
            return;
        }

        const usersOfServerAdmin = selected.current.filter(s => items[s].roleName === userConstants.ROLE_NAME_SERVER_ADMINISTRATOR);
        if (usersOfServerAdmin.length > 0 && isServerAdmin === false) {
            toastr.error(t('MESSAGE_USER_CANT_DELETE_ALL_ADMIN_P'));
            return;
        }

        const userIds = selected.current.map(s => items[s].userInfo.userId);

        addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});

        userService.withdrawnUsers({ids: userIds, reason: reasonText}).then(() => {
            toastr.success(t('TEXT_SUCCESS_P'));
        }).catch(error => {
            let failList = error.items !== undefined ? error.items.withdrawalFailList: error.body.items.withdrawalFailList;
            if (failList.length > 0) {
                if (failList[0].reason.includes("At least one administrator")) {
                    toastr.error(t('MIS_SID_SERVER_ONE_ADMINISTRATOR_REQUIRED_ORGANIZATION'));
                } else {
                    toastr.error(failList[0].reason);
                }
            }
        }).finally(() => {
            updateSubMenu();
            setFilter({...filter});
            reloadGroups('USER_BY_GROUP', organizationId);
            closePopup(commonConstants.LOADING_POPUP);
        });
    };

    const handleChangeOrganization = (orginzationId, isCopyContents) => {
        const userIds = selected.current.map(s => items[s].userInfo.userId);

        addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});

        userService.moveOrganization(orginzationId, {
            copyContentsToNewOrganization: isCopyContents,
            userIds: userIds
        }).then(res => {
            fetchData();
            if (res.items.unSavedUserIds.length > 0) {
                toastr.error(t('MESSAGE_DEVICE_NOT_MOVE_ORG_P'));
            } else {
                toastr.success(t('TEXT_SUCCESS_P'));
                reloadGroups("USER_BY_GROUP");
            }
        }).catch(error => {
            let errorCode = error.errorCode !== undefined ? error.errorCode : error.body.errorCode;
            if (errorCode === '400716') {
                toastr.error(t('MIS_SID_SERVER_ONE_ADMINISTRATOR_REQUIRED_ORGANIZATION'));
            } else if (errorCode === '401001') {
                toastr.error(t('MIS_SID_20_THE_USER_IS_NOT_AUTHORIZED'));
            } else if (errorCode === '403001') {
                toastr.error(t('ERROR_NO_ROLE'));
            } else {
                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
            }
        }).finally(() => {
            updateSubMenu();
            closePopup(commonConstants.LOADING_POPUP);
            closePopup(commonConstants.CHANGE_ORGANIZATION);
        });
    };

    const handleChangeRole = (roleId) => {
        const userIds = selected.current.map(s => items[s].userInfo.userId);

        addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});

        userService.updateUsersByRole(roleId, {copyContentsToNewOrganization: true, ids: userIds}).then(() => {
            toastr.success(t('TEXT_SUCCESS_P'));
            closePopup(commonConstants.CHANGE_ROLE);
            fetchData();
        }).catch(error => {
            let errorCode = error.errorCode !== undefined ? error.errorCode : error.body.errorCode;
            if (errorCode === '400716') {
                toastr.error(t('MIS_SID_SERVER_ONE_ADMINISTRATOR_REQUIRED_ORGANIZATION'));
            } else if (errorCode === '401001') {
                toastr.error(t('MIS_SID_20_THE_USER_IS_NOT_AUTHORIZED'));
            } else if (errorCode === '403001') {
                toastr.error(t('ERROR_NO_ROLE'));
            } else {
                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
            }
        }).finally(() => {
            closePopup(commonConstants.LOADING_POPUP);
        });
    };

    const addUserPopup = (id) => {
        addPopup({
            id: id,
            type: commonConstants.ADD_USER,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
            organizationId: filter.mode && filter.mode === 'ALL' ? -1 : parseInt(props.organizationId), //Popup want to use integer for value
            groupId: filter.mode && filter.mode === 'ALL' ? -1 : (filter.groupId === undefined ? -1 : parseInt(filter.groupId)),
            onClickYes: () => {
                setFilter({...filter});
                reloadGroups('USER_BY_GROUP', organizationId);
            },
            onClose: () => closePopup(id)
        });
    };

    const addOrgPopup = (id) => {
        addPopup({
            id: id,
            type: commonConstants.ADD_ORGANIZATION,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
            onClickYes: () => {
                setFilter({...filter});
                reloadGroups('USER_BY_GROUP', organizationId);
            },
            onClose: () => closePopup(id)
        });
    };

    const removeUserPopup = (id) => {
        const {addPopup, closePopup} = props;
        const userIds = selected.current.map(s => items[s].userInfo.userId);

        userService.fetchUserContentsAdministrators({ids: userIds}).then(res => {
            if (res.items.length > 0) {
                addPopup({
                    type: commonConstants.COMMON_CONFIRM_POPUP,
                    id: "DELETE_CONTENTS_USER",
                    title: t("TEXT_TITLE_USER_DELETE_P"),
                    useMessageLine: false,
                    message: (
                        <div>
                            <table>
                                <colgroup>
                                    <col width=""/>
                                </colgroup>
                                <tbody>
                                <tr>
                                    <td style={{width: '250px', overflow: "hidden", whiteSpace: "pre-wrap"}}>
                                        {t('MIS_SID_USER_CONTENT_UPLOADED_CREATED_MSG')}<br/>{t('MIS_SID_SUARE_WANTTO_DELETE_USER')}
                                        <div className="user_pop_list mt20" style={{height: '140px', overflow: "auto"}}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td>{t('TABLE_ORGANIZATION_P')}</td>
                                                    <td>{t('COM_TEXT_USER_NAME_P')}</td>
                                                    <td>{t('COM_TV_SID_EMAIL')}</td>
                                                </tr>
                                                {
                                                    res.items.map(result =>
                                                        <tr>
                                                            <td>{result.organizationName}</td>
                                                            <td>{result.userName}</td>
                                                        <td>{result.email}</td>
                                                        </tr>)
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    ),
                    yesTitle: t('BUTTON_YES_P'),
                    noTitle: t('BUTTON_NO_P'),
                    width: 380,
                    height: 200,
                    onClickYes: () => {
                        closePopup("DELETE_CONTENTS_USER");
                        addPopup({
                            type: commonConstants.COMMON_CONFIRM_POPUP,
                            id: "DELETE_CONTENTS_USER_WARNING",
                            title: t("TEXT_TITLE_WARNING_P"),
                            message: t("MIS_SID_ONCE_APPLIED_NOT_UNDO_MSG"),
                            yesTitle: t('MIS_SID_APPLY_CHANGES'),
                            noTitle: t('BUTTON_NO_P'),
                            onClickYes: () => {
                                closePopup("DELETE_CONTENTS_USER_WARNING");
                                addPopup({
                                    type: commonConstants.REMOVE_USER,
                                    id: id,
                                    title: t("COM_BUTTON_DELETE"),
                                    message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
                                    onClickYes: handleRemoveUsers,
                                    onClose: () => closePopup(id)
                                });
                            },
                            onClose: () => closePopup("DELETE_CONTENTS_USER_WARNING")
                        });
                    },
                    onClose: () => closePopup("DELETE_CONTENTS_USER"),
                });
            } else {
                addPopup({
                    type: commonConstants.REMOVE_USER,
                    id: id,
                    title: t("COM_BUTTON_DELETE"),
                    message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
                    onClickYes: handleRemoveUsers,
                    onClose: () => closePopup(id)
                });
            }
        }).catch(error => {
            let errorCode = error.errorCode !== undefined ? error.errorCode : error.body.errorCode;
            if (errorCode === '400716') {
                toastr.error(t('MIS_SID_SERVER_ONE_ADMINISTRATOR_REQUIRED_ORGANIZATION'));
            } else if (errorCode === '401001') {
                toastr.error(t('MIS_SID_20_THE_USER_IS_NOT_AUTHORIZED'));
            } else if (errorCode === '403001') {
                toastr.error(t('ERROR_NO_ROLE'));
            } else {
                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
            }
        })
    };

    const changeOrgPopup = (id) => {
        const usersOfAdmin = selected.current.filter(s => items[s].roleName === userConstants.ROLE_NAME_ADMINISTRATOR);
        if (usersOfAdmin.length > 0) {
            toastr.error(t('MESSAGE_USER_CANT_EDIT_ORGAN_ADMIN_P'));
            return;
        }

        addPopup({
            type: commonConstants.CHANGE_ORGANIZATION,
            id: id,
            title: t("BUTTON_EDIT_ORGAN_P"),
            message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
            onClickYes: handleChangeOrganization,
            onClose: () => closePopup(id)
        });
    };

    const changeRolePopup = (id) => {
        Promise.all([
            userService.fetchOrganizations()
        ]).then(res => {
            let organizationName = 'Administrators';
            let organizationId = userConstants.ORGAN_ID_ADMINISTRATOR;

            //[SF00208444]- Show Server Administrator role if and only if organization of user whose role is changing is ROOT
            if(selected.current.length>0){
                organizationName = selected.current.map(s => items[s].organizationName)[0];
               
                if(selected.current.length>1){
                    
                    const notRootOrg= selected.current.filter(s => items[s].organizationName!==userConstants.ORGAN_NAME_API_ADMINISTRATORS);
                    if(notRootOrg.length>0){
                        organizationName = notRootOrg.map(s => items[s].organizationName)[0];
                    }               
                }

                let currentOrgItem = res[0].items.filter(item => item.groupName === organizationName);
                organizationId = currentOrgItem.length > 0 ? currentOrgItem[0].organizationId : -1;
            }

            addPopup({
                type: commonConstants.CHANGE_ROLE,
                id: id,
                title: t("TEXT_TITLE_ROLE_CHANGE_P"),
                message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
                organizationName: organizationName,
                organizationId: organizationId,
                onClickYes: handleChangeRole,
                onClose: () => closePopup(id)
            });

        }).catch(error => {
            toastr.error(error.statusText);
        });
    };

    const handleChangeDevicePermission = (groups) => {
        let userId = items[selected.current[0]].userInfo.userId;
        let selectedGroupIds = groups.map(group => group.groupId);

        addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});

        userService.updateUserDevicePermissionById(userId, {ids: selectedGroupIds}).then(() => {
            toastr.success(t('TEXT_SUCCESS_P'));
            fetchData();
        }).catch(error => {
            let errorCode = error.errorCode !== undefined ? error.errorCode : error.body.errorCode;
            if (errorCode === '400716') {
                toastr.error(t('MIS_SID_SERVER_ONE_ADMINISTRATOR_REQUIRED_ORGANIZATION'));
            } else {
                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
            }
        }).finally(() => {
            closePopup(commonConstants.LOADING_POPUP);
            closePopup('USER_DEVICE_PERMISSION');
        });
    };

    const devicePermPopup = (id) => {
        let userId = items[selected.current[0]].userInfo.userId;

        Promise.all([
            userService.fetchUserDevicePermissionById(userId),
            userService.fetchOrganizations()
        ]).then(res => {
            let deviceGroupIds = res[0].items.filter(item => item.status.selected).map(item => item.groupId);
            let currentOrgItem = res[1].items.filter(item => item.groupName === items[selected.current[0]].organizationName);
            let currentOrganizationId = currentOrgItem.length > 0 ? currentOrgItem[0].organizationId : '';

            addPopup({
                type: commonConstants.DEVICE_PERMISSION,
                id: id,
                title: t("TEXT_SEL_GROUP_P"),
                message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
                onSave: handleChangeDevicePermission,
                deviceGroupIds: deviceGroupIds,
                organizationId: currentOrganizationId,
                userPermissionItems: res[0].items,
                onClose: () => closePopup(id)
            });
        });
    };

    const editUserPopup = (userId, id) => {
        Promise.all([
            userService.fetchUserById(userId),
            userService.fetchOrganizations()
        ]).then(res => {
            let currentOrgItem = res[1].items.filter(item => item.groupName ===
                (res[0].items.organizationName === userConstants.ORGAN_NAME_ADMINISTRATORS ? 'ROOT' : res[0].items.organizationName));
            let currentOrganizationId = currentOrgItem.length > 0 ? currentOrgItem[0].organizationId : -1;

            addPopup({
                type: commonConstants.EDIT_USER,
                id: id,
                userItem: res[0].items,
                organizationId: currentOrganizationId,
                onClickYes: (adminUsers, userId, data) => {
                        if(adminUsers){
                            showEditOrganizationContentUser(adminUsers, userId, data);
                        }else{
                            setFilter({...filter});
                        }
                    },
                onClose: () => closePopup(id)
            });
        }).catch(error => {
            toastr.error(error.statusText);
        });
    };

    const showEditOrganizationContentUser = (adminUsers, userId, data) => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: "EDIT_ORG_CONTENTS_USER",
            title: t("MIS_SID_SERVER_EDIT_ORGANIZATION_GROUP"),
            useMessageLine: false,
            message: (
                <div>
                    <table>
                        <colgroup>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <td style={{width: '250px', overflow: "hidden", whiteSpace: "pre-wrap"}}>
                                {t('MIS_SID_USER_CONTENT_UPLOADED_CREATED_MSG')}<br/>{t('MIS_SID_WANTTO_EDIT_ORGANIZTION_GROUP')}
                                <div className="user_pop_list mt20" style={{height: '140px', overflow: "auto"}}>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td>{t('TABLE_ORGANIZATION_P')}</td>
                                            <td>{t('COM_TEXT_USER_NAME_P')}</td>
                                            <td>{t('COM_TV_SID_EMAIL')}</td>
                                        </tr>
                                        {
                                            adminUsers.map(result =>
                                                <tr>
                                                    <td>{result.organizationName}</td>
                                                    <td>{result.userName}</td>
                                                    <td>{result.email}</td>
                                                </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ),
            yesTitle: t('BUTTON_YES_P'),
            noTitle: t('BUTTON_NO_P'),
            width: 380,
            height: 200,
            onClickYes: () => {
                closePopup("EDIT_ORG_CONTENTS_USER");
                addPopup({
                    type: commonConstants.COMMON_CONFIRM_POPUP,
                    id: "EDIT_ORG_CONTENTS_USER_WARNING",
                    title: t("TEXT_TITLE_WARNING_P"),
                    message: t("MIS_SID_ONCE_APPLIED_NOT_UNDO_MSG"),
                    yesTitle: t('MIS_SID_APPLY_CHANGES'),
                    noTitle: t('BUTTON_NO_P'),
                    width: 380,
                    height: 200,
                    onClickYes: () => {
                        closePopup("EDIT_ORG_CONTENTS_USER_WARNING");
                        data = merge({}, data, {
                            checkHasResources : false
                        });
                        userService.updateUsers(userId, data).then(() => {
                            toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                            setFilter({...filter});
                        });
                    },
                    onClose: () => closePopup("EDIT_ORG_CONTENTS_USER_WARNING"),
                });
            },
            onClose: () => closePopup("EDIT_ORG_CONTENTS_USER"),
        });
    }

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox id={'AllUser_all'}
                              classname={"table"}
                              name={"check"}
                              onChange={toggleSelectAll}
                              ref={checkAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox id={items[row.index].userInfo.userId}
                              index={row.index}
                              classname="table"
                              name="check"
                              onChange={toggleRow}
                              ref={setCheckBoxRefs}
                    />
                )
            },
            width: 46,
            sortable: false,
            resizable: false
        },
        {
            Header: t("COM_DID_ADMIN_USER_USERID"),
            accessor: "userInfo.userId",
            Cell: (props) => <span className={"data_name"}
                                   onClick={() => editUserPopup(props.original.userInfo.userId, 'EDIT_USER')}
                                   onMouseDown={(e) => e.preventDefault()}>
                                    {getUnRecognizedData(props.original.userInfo.userId)}</span>,
            width: 200
        },
        {
            Header: t("COM_TEXT_USER_NAME_P"),
            accessor: 'userInfo.userName',
            width: 220

        },
        {
            Header: t("SETUP_NEW_STRING29_P"),
            accessor: "organizationName",
            width: 200
        },
        {
            Header: t("COM_TABLE_GROUP_NAME_P"),
            accessor: "groupName",
            width: 200
        },
        {
            Header: t("TABLE_ROLE_NAME_P"),
            accessor: "roleName",
            width: 230
        },
        {
            Header: "LDAP ID",
            accessor: "ldapId",
            width: 200
        },
        {
            Header: t("TEXT_JOIN_DATE_P"),
            accessor: "joinDate",
            Cell: props => <DeviceRelativeTimeCell value={props.original.joinDate} />,
            minWidth: 170
        }
    ], [items]);

    const [password, setPassword] = useState('');

    const changeMode = (mode) => {

        //alert(mode);
        if("UPDATE" === mode){
            setReconfirm(true);
            //[V9 RC #12][6. Fetch users data only after successful authorization of current user]
            fetchData();
        }
    }


    const changePassword = (password) => {
        //    checkPassword(data.items, password);
    }

    const selectedUserContainsAdminCheck  = () => {
        if(selectedCnt < 1){
            return true;
        }
        return selected.current.filter(Idx => {
           const selectedUserInfo = items[Idx];
           return (selectedUserInfo && selectedUserInfo.userInfo.userId === userConstants.USER_ID_ADMIN && selectedUserInfo.roleName === userConstants.ROLE_NAME_SERVER_ADMINISTRATOR)
        }).length > 0;
    }
    ////////////////////////////////////////////////////////////////////////////////


    const [getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'user_tr_group');

    const buttonDisplayStatus = checkButtonDisplayWithAuthority(userAuthority, logInGroupId, isServerAdmin, isMultiOrganizationManager, logInRoleName);

    const confirmMode = "CONFIRM_PASSWORD";

    return (
        <>
            <div style={{width: '100%', height: '100%', display: reconfirm ? 'none':'block'}}>
                <ConfirmPassword onChangeMode={(confirmMode)=>changeMode(confirmMode)} password={password} changePassword={changePassword} currContent={"ALL_USER"} />
            </div>

            {
                reconfirm &&
                <div style={{width: '100%', height: '100%', display: (props.currContent === 'ALL_USER' ||  props.currContent === 'USER_BY_GROUP') ? 'block':'none'}}>
                    <div className="contents_buttonWrap">
                        <div className="leftButton">
                            <WhiteButton id={"ADD_USER"} name={t("TEXT_TITLE_ADD_USER_P")}
                                         onClick={() => addUserPopup('ADD_USER')}
                                         authority={buttonDisplayStatus[userConstants.BUTTON_KEY_ADD_USER]}
                            />
                            <WhiteButton id={"ADD_ORGANIZATION"} name={t("TEXT_TITLE_CREATE_ORGAN_P")}
                                         onClick={() => addOrgPopup('ADD_ORGANIZATION')}
                                         authority={buttonDisplayStatus[userConstants.BUTTON_KEY_ADD_ORGANIZATION]}
                            />
                            <WhiteButton id={"REMOVE_USER"} name={t("COM_BUTTON_DELETE")}
                                         onClick={() => removeUserPopup('REMOVE_USER')}
                                         authority={buttonDisplayStatus[userConstants.BUTTON_KEY_REMOVE_USER]}
                                         disable={selectedCnt < 1}
                            />
                            <WhiteButton id={"CHANGE_ORGANIZATION"} name={t("BUTTON_EDIT_ORGAN_P")}
                                         onClick={() => changeOrgPopup('CHANGE_ORGANIZATION')}
                                         authority={buttonDisplayStatus[userConstants.BUTTON_KEY_CHANGE_ORGANIZATION]}
                                         disable={selectedCnt < 1 || _setChangeOrgBtnStatus()}
                            />
                            <WhiteButton id={"CHANGE_ROLE"} name={t("TABLE_CHANGE_ROLE_P")}
                                         onClick={() => changeRolePopup('CHANGE_ROLE')}
                                         authority={buttonDisplayStatus[userConstants.BUTTON_KEY_CHANGE_ROLE]}
                                         disable={selectedUserContainsAdminCheck()}
                            />
                            <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")}
                                         authority={buttonDisplayStatus[userConstants.BUTTON_KEY_CONTENT_EXPORT]}
                                         onClick={() => onClickExport('EXCEL')}/>

                            <WhiteButton id={"USER_DEVICE_PERMISSION"} name={t("MIS_TEXT_DEVICE_PERMISSIONS_P")}
                                         authority={buttonDisplayStatus[userConstants.BUTTON_KEY_USER_DEVICE_PERMISSION]}
                                         onClick={() => devicePermPopup('USER_DEVICE_PERMISSION')}
                                         disable={selectedCnt !== 1 || items[selected.current[0]] === undefined || items[selected.current[0]].hasDevicePermission === false}
                            />
                        </div>
                        <div className="rightButton">
                            <SearchBar placeholder={`${t('COM_DID_ADMIN_USER_USERID')}, ${t('COM_TEXT_USER_NAME_P')}`}
                                       onClickSearch={onKeywordChange}
                                       onClickDetailSearch={onClickDetailSearch}
                                       callbackClearSearchFilter={initFilterValues}
                                       forceDisable={filter.isSearching === false}
                                       callbackEnableStatus={() => {filter.isSearching = true}}
                                       keyword={keyword}
                                       enableDetail={true}
                                       searchDetails={[
                                           {title: 'organization', type: 'select', disable: filter.mode === 'GROUPED'},
                                           {title: 'group', type: 'popup'},
                                           {title: 'role', type: 'select'},
                                           {title: 'date', type: 'date'}
                                       ]}
                            />
                        </div>
                    </div>

                    <div className='user_list_view'>
                        <MagicInfoTable
                            data={items}
                            minRows={0}
                            page={page}
                            pages={pages}
                            loading={loading}
                            sorted={sorted}
                            showPagination={false}
                            columns={columns}
                            onSortedChange={onSortedChange}
                            className="-striped -highlight"
                            style={style}
                            getTrGroupProps={getTrGroupPropsType2}
                        />
                        <Pagination totalCount={totalCount}
                                    page={page}
                                    defaultPageSize={pageSize}
                                    pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS}
                                    onPageChange={onPageChange}
                                    onPageSizeChange={onPageSizeChange}
                                    divide={props.divide}/>
                    </div>
                </div>
            }


        </>
    );
};

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        openDetailView: (type) => dispatch(popupAction.openDetailView(type)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        updateSubMenu : () => dispatch(menuAction.updateSubMenuCounter('USER')),
        reloadGroups: (submenuId, groupId) => dispatch(menuAction.reloadGroup(submenuId, groupId)),
        loadGroup: (submenuId, nodeId) => dispatch(menuAction.loadGroup(submenuId, nodeId))
    })
)(AllUser);
